export const Z_INDEX_CONTENT = 0;
export const Z_INDEX_CONTENT_OVERLAY = 1;
export const Z_INDEX_SUB_NAV = 2;
export const Z_INDEX_HEADER = 3;
export const Z_INDEX_NAV = 4;
export const Z_INDEX_NAV_OVERLAY = 5;
export const Z_INDEX_MODAL = 6;
export const Z_INDEX_CURSOR = 7;
export const Z_INDEX_PAGE_TRANSITIONS = 8;
export const Z_INDEX_DEBUG = 9;
