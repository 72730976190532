import React, {createContext} from 'react';

import {Globals} from '@/content/cms/types';

interface Props {
  globals: Globals;
}

const GlobalsContext = createContext<Globals>({} as Globals);

const GlobalsProvider: React.FC<Props> = ({globals, children}) => {
  return (
    <GlobalsContext.Provider value={globals}>
      {children}
    </GlobalsContext.Provider>
  );
};

const useGlobals = () => React.useContext(GlobalsContext);

export {GlobalsProvider, useGlobals};
