import {KeyboardEvent} from 'react';

import {EventDataProps} from '@phntms/react-gtm';

import {theme} from '@/content/cms/types';

import {StyledSocial} from './styles';

interface Props {
  className?: string;
  url?: string;
  tracking: EventDataProps;
  label: string;
  theme?: theme;
  targetStrokeOverPath?: boolean;
  small?: boolean;
  onClick?: () => void;
}

const Social: React.FC<Props> = ({
  className,
  label,
  url,
  tracking,
  theme = 'dark',
  targetStrokeOverPath,
  small,
  onClick,
  children,
  ...rest
}) => {
  const handleKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
    if (onClick && event.key === 'Enter') onClick();
  };

  return (
    <StyledSocial
      className={className}
      href={url}
      tracking={tracking}
      external
      onClick={onClick}
      onKeyPress={handleKeyPress}
      $theme={theme}
      $targetStrokeOverPath={targetStrokeOverPath}
      $small={small}
      aria-label={label}
      tabIndex={0}
      {...rest}
    >
      {children}
    </StyledSocial>
  );
};

export default Social;
