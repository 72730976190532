import {motion} from 'framer-motion';
import styled, {css} from 'styled-components';

import TextReveal from '@/components/TextReveal';
import {theme} from '@/content/cms/types';
import {COLOR_ORANGE, COLOR_WHITE, COLOR_ALMOST_BLACK} from '@/theme/colors';
import {pxToRem} from '@/theme/grid';
import {ALLOW_CLICKS} from '@/theme/pointerEvents';
import {SPACING_M} from '@/theme/spacings';
import {
  TRANSITION_EASE_CUBIC,
  TRANSITION_SPEED_RAPID,
  TRANSITION_SPEED_REGULAR,
} from '@/theme/transitions';
import {TYPE_SIZE_SANS_REGULAR} from '@/theme/type';
import {Z_INDEX_NAV_OVERLAY} from '@/theme/zIndex';

export const TOGGLE_SIZE = pxToRem(40);
export const TOGGLE_HALF_SIZE = pxToRem(20);

export const NavToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  z-index: ${Z_INDEX_NAV_OVERLAY};
  cursor: pointer;
  ${ALLOW_CLICKS}
`;

interface ToggleProps {
  $hovering: boolean;
}

export const Toggle = styled(motion.div)<ToggleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: ${TOGGLE_SIZE};
  width: ${TOGGLE_SIZE};
  cursor: pointer;

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    transition: transform ${TRANSITION_SPEED_REGULAR}s ${TRANSITION_EASE_CUBIC};
  }

  &:before {
    transform: scale(0);
    border: 1px solid ${COLOR_ORANGE};
  }

  &:after {
    transform: scale(1);
    background: ${COLOR_ORANGE};
  }

  &:focus {
    outline: 0;

    /* On focus, expand border beyond size to mimic outline */
    &:before {
      transform: scale(1.2);
    }

    &:not(:focus-visible):before {
      transform: scale(0);
    }
  }

  ${({$hovering}) =>
    $hovering &&
    css`
      &:before {
        transform: scale(1);
        transition-delay: ${TRANSITION_SPEED_RAPID}s;
      }

      &:after {
        transform: scale(0.25);
      }
    `}
`;

export const LabelWrapper = styled(motion.span)`
  display: flex;
`;

interface TextRevealProps {
  $theme?: theme;
}

export const StyledTextReveal = styled(TextReveal)<TextRevealProps>`
  margin-right: ${SPACING_M};
  ${TYPE_SIZE_SANS_REGULAR}
  color: ${({$theme}) =>
    $theme === 'dark' ? COLOR_ALMOST_BLACK : COLOR_WHITE};
`;
