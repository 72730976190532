// BREAKPOINTS

import {css} from 'styled-components';

import Vector2 from '@/utils/math/Vector2';

const pxToEm = (px: number) => px / 16;

export const BREAKPOINT_MOBILE = 768;
const BREAKPOINT_MOBILE_AS_EM = `${pxToEm(BREAKPOINT_MOBILE)}em`;
/** 768px. */
export const QUERY_GREATER_THAN_MOBILE = `(min-width: ${BREAKPOINT_MOBILE_AS_EM})`;

export const BREAKPOINT_TABLET = 1025;
const BREAKPOINT_TABLET_AS_EM = `${pxToEm(BREAKPOINT_TABLET)}em`;
/** 1024px. */
export const QUERY_GREATER_THAN_TABLET = `(min-width: ${BREAKPOINT_TABLET_AS_EM})`;

export const BREAKPOINT_DESKTOP = 1440;
const BREAKPOINT_DESKTOP_AS_EM = `${pxToEm(BREAKPOINT_DESKTOP)}em`;
/** 1440px. */
export const QUERY_GREATER_THAN_DESKTOP = `(min-width: ${BREAKPOINT_DESKTOP_AS_EM})`;

// OTHER

/** Media query to return if primary input device is mouse. */
export const USING_MOUSE = '(pointer: fine)';

/** Media query to return if primary input device is finger. */
export const USING_TOUCH = '(pointer: coarse)';

/** Media query to return if user is using IE. */
export const IS_IE =
  'all and (-ms-high-contrast: none), (-ms-high-contrast: active)';

/** Media query to return if user isn't using Firefox. */
export const IS_NOT_FIREFOX = '(-webkit-user-drag: none)';

/** Media query to return if device is landscape. */
export const IS_LANDSCAPE = '(min-aspect-ratio: 13/9)';

/** Aspect ratio with fallback. */
export const aspectRatio = (aspectRatio: Vector2) => css`
  aspect-ratio: ${aspectRatio.x} / ${aspectRatio.y};
  width: 100%;

  @supports not (aspect-ratio: auto) {
    &:after {
      content: '';
      display: block;
      padding-bottom: ${(aspectRatio.y / aspectRatio.x) * 100}%;
    }
  }
`;
