import styled, {css} from 'styled-components';

import {SPACING_S} from '@/theme/spacings';
import {
  TYPE_SIZE_REGULAR,
  TYPE_SIZE_LARGE,
  TYPE_FONT_SERIF,
  TYPE_SIZE_SANS_REGULAR,
  TYPE_SIZE_SANS_SMALL,
} from '@/theme/type';

import {sansSizes, serifSizes} from './';

export const COPY_SANS_SIZES = {
  small: TYPE_SIZE_SANS_SMALL,
  regular: TYPE_SIZE_SANS_REGULAR,
};

export const COPY_SERIF_SIZES = {
  regular: TYPE_SIZE_REGULAR,
  large: TYPE_SIZE_LARGE,
};

interface Props {
  $serifSize: serifSizes;
  $sansSize?: sansSizes;
}

export const StyledCopy = styled.p<Props>`
  margin: 0 0 ${SPACING_S};

  ${({$sansSize, $serifSize}) =>
    $sansSize
      ? COPY_SANS_SIZES[$sansSize]
      : css`
          ${TYPE_FONT_SERIF}
          ${COPY_SERIF_SIZES[$serifSize]}
        `};
`;
