import {pxToRem} from '@/theme/grid';
import {SVGRProps} from '@/utils/svgrProps';

import IconWrapper from '../IconWrapper';
import PlaySVG from './icon.svg';

const Play: React.FC<SVGRProps> = ({
  className,
  width = pxToRem(12),
  height = pxToRem(12),
}) => (
  <IconWrapper width={width} height={height} className={className}>
    <PlaySVG width="100%" height="100%" />
  </IconWrapper>
);

export default Play;
