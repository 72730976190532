import {motion} from 'framer-motion';
import styled, {css} from 'styled-components';

export const TextRevealWrapper = styled(motion.span)`
  display: block;
`;

interface Props {
  $overflowHidden?: boolean;
}

export const Mask = styled.span<Props>`
  display: inline-flex;

  ${({$overflowHidden}) =>
    $overflowHidden &&
    css`
      overflow: hidden;
    `}
`;

export const INNER_TAG_BASE = css`
  white-space: break-spaces;
  display: inline-block;
`;

export const Inner = styled(motion.span)`
  ${INNER_TAG_BASE}
`;
