import {theme} from '@/content/cms/types';
import {pxToRem} from '@/theme/grid';
import {SVGRProps, Direction} from '@/utils/svgrProps';

import IconWrapper from '../IconWrapper';
import {StyledAngle} from './styles';

interface Props extends SVGRProps {
  direction?: Direction;
  theme?: theme;
}

const Angle: React.FC<Props> = ({
  className,
  direction = Direction.Right,
  width = pxToRem(15),
  height = pxToRem(15),
  theme = 'dark',
}) => (
  <IconWrapper width={width} height={height} className={className}>
    <StyledAngle
      width="100%"
      height="100%"
      $direction={direction}
      $theme={theme}
    />
  </IconWrapper>
);

export default Angle;
