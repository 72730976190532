import {
  QUERY_GREATER_THAN_MOBILE,
  QUERY_GREATER_THAN_TABLET,
} from '@/theme/mediaQueries';

export const IS_BROWSER = typeof window !== 'undefined';

export const IS_MOBILE = IS_BROWSER
  ? !window.matchMedia(QUERY_GREATER_THAN_MOBILE).matches
  : true;

export const IS_TABLET = IS_BROWSER
  ? !window.matchMedia(QUERY_GREATER_THAN_TABLET).matches
  : true;

export const IS_SAFARI =
  IS_BROWSER && /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
