import {SVGRProps} from '@/utils/svgrProps';

import {ICON_WRAPPER_CLASS, StyledIconWrapper} from './styles';

/**
 * Used to wrap SVG icons so they can be sized responsively
 * without browser compatibility issues related to using
 * max() / rem values for size attributes in SVG.
 */
const IconWrapper: React.FC<SVGRProps> = ({
  children,
  height,
  width,
  className,
}) => {
  const fullClassName = className
    ? `${className} ${ICON_WRAPPER_CLASS}`
    : ICON_WRAPPER_CLASS;
  return (
    <StyledIconWrapper
      $height={height}
      $width={width}
      className={fullClassName}
    >
      {children}
    </StyledIconWrapper>
  );
};

export default IconWrapper;
