import {AnchorHTMLAttributes, MouseEvent} from 'react';
import React from 'react';

import {EventDataProps, trackEvent} from '@phntms/react-gtm';
import isAbsoluteUrl from 'is-absolute-url';
import NextLink from 'next/link';
import {useRouter} from 'next/router';

import {CursorType} from '@/components/Cursor';
import {getSlugPath} from '@/config/routes';
import {Link as ContentfulLink} from '@/content/cms/types';
import {useCursor} from '@/contexts/cursor';

interface Props extends AnchorHTMLAttributes<HTMLElement> {
  className?: string;
  href?: string;
  link?: ContentfulLink;
  external?: boolean;
  tracking: EventDataProps;
  cursor?: CursorType;
  disable?: boolean;
  allowRoutingToSamePage?: boolean;
}

const Link: React.ForwardRefRenderFunction<HTMLAnchorElement, Props> = (
  {
    className,
    href: suppliedHref,
    link,
    external: suppliedExternal = false,
    tracking,
    cursor = CursorType.None,
    disable,
    children,
    allowRoutingToSamePage = false,
    ...rest
  },
  ref,
) => {
  let href = suppliedHref;

  // CMS link type support
  if (link) href = link.page ? getSlugPath(link.page.slug) : link.url;
  const external = link?.url ? isAbsoluteUrl(link.url) : suppliedExternal;

  // Remove trailing '#' and '/', but ignore trailing slash if first
  const {asPath} = useRouter();
  const asPathWithoutHashOrTrailingSlash =
    asPath === '/' ? asPath : asPath.replace(/#$|\/$/, '');

  if (!allowRoutingToSamePage && asPathWithoutHashOrTrailingSlash === href) {
    href = undefined;
  }

  const {setType} = useCursor();

  if (!href || disable) {
    return (
      <span ref={ref} className={className} {...rest}>
        {children}
      </span>
    );
  }

  const cursorInteractions = {
    onMouseOver: (event: MouseEvent<HTMLElement>) => {
      if (rest.onMouseOver) rest.onMouseOver(event);
      setType(cursor);
    },
    onMouseOut: (event: MouseEvent<HTMLElement>) => {
      if (rest.onMouseOut) rest.onMouseOut(event);
      setType(CursorType.None);
    },
    onClick: (event: MouseEvent<HTMLElement>) => {
      if (rest.onClick) rest.onClick(event);
      setType(CursorType.None);
      trackEvent({data: tracking});
    },
  };

  return external ? (
    <a
      ref={ref}
      className={className}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      {...rest}
      {...cursorInteractions}
    >
      {children}
    </a>
  ) : (
    <NextLink href={href} passHref scroll={false}>
      <a ref={ref} className={className} {...rest} {...cursorInteractions}>
        {children}
      </a>
    </NextLink>
  );
};

export default React.forwardRef(Link);
