import {motion} from 'framer-motion';
import styled, {css} from 'styled-components';

import Link from '@/components/Link';
import Logo from '@/components/Logo';
import {
  LOGO_HEIGHT_DESKTOP,
  LOGO_HEIGHT_MOBILE,
} from '@/components/Logo/LargeLogo/styles';
import Media from '@/components/Media';
import Socials from '@/components/Socials';
import {onFocusOutline, OUTLINE_DISTANCE} from '@/theme/borders';
import {COLOR_ALMOST_BLACK, COLOR_ORANGE, COLOR_WHITE} from '@/theme/colors';
import {gridGutters} from '@/theme/grid';
import {
  QUERY_GREATER_THAN_MOBILE,
  QUERY_GREATER_THAN_TABLET,
} from '@/theme/mediaQueries';
import {ALLOW_CLICKS} from '@/theme/pointerEvents';
import {SPACING_L, SPACING_M, SPACING_S, SPACING_XL} from '@/theme/spacings';
import {
  TRANSITION_SPEED_FAST,
  TRANSITION_EASE_CUBIC,
} from '@/theme/transitions';
import {
  TYPE_SIZE_HEADER_3,
  TYPE_SIZE_SANS_REGULAR,
  TYPE_WEIGHT_BOLD,
} from '@/theme/type';
import {insetUnderline, UNDERLINE_H3} from '@/theme/underlines';
import {Z_INDEX_NAV} from '@/theme/zIndex';

export const NavWrapper = styled(motion.div)`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 100vh;
  display: block;
  color: ${COLOR_WHITE};
  overflow-y: auto;
  background: ${COLOR_ALMOST_BLACK};
  z-index: ${Z_INDEX_NAV};
  padding: calc(${LOGO_HEIGHT_MOBILE} + ${SPACING_M} + ${SPACING_XL})
    ${gridGutters(1)} ${gridGutters(1)};
  will-change: clip-path;
  ${ALLOW_CLICKS}

  @media ${QUERY_GREATER_THAN_MOBILE} {
    padding: calc(${LOGO_HEIGHT_DESKTOP} + ${SPACING_L} + ${SPACING_L})
      ${gridGutters(1)} ${gridGutters(1)};
    column-gap: ${gridGutters(2)};
    display: flex;
  }
`;

export const StyledLogo = styled(Logo)`
  position: absolute;
  top: ${SPACING_M};
  left: ${gridGutters(1)};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    top: ${SPACING_L};
  }
`;

export const StyledMedia = styled(Media)`
  &&& {
    display: none;

    @media ${QUERY_GREATER_THAN_TABLET} {
      display: block;
      height: 100%;
      width: ${gridGutters(6)};
      padding-top: ${SPACING_S};
    }
  }
`;

export const StyledLink = styled(Link)`
  display: block;
  width: fit-content;

  /* Remove default link styles */
  color: inherit;
  text-decoration: none;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 100%;

  @media ${QUERY_GREATER_THAN_MOBILE} {
    padding-top: ${SPACING_L};
  }

  @media ${QUERY_GREATER_THAN_TABLET} {
    padding-top: ${SPACING_XL};
    width: auto;
  }
`;

interface ContentTopOuterLinkWrapperProps {
  $index: number;
}

export const ContentTopOuterLinkWrapper = styled(
  motion.div,
)<ContentTopOuterLinkWrapperProps>`
  overflow: hidden;

  grid-area: ${({$index}) =>
      $index % 2 === 0 ? $index / 2 + 1 : Math.ceil($index / 2) + 4} / 1;

  @media ${QUERY_GREATER_THAN_MOBILE} {
    grid-area: auto;
  }
`;

export const ContentTopInnerLinkWrapper = styled(motion.div)`
  ${TYPE_SIZE_HEADER_3}

  /* todo(paulo) Fix 'overflow: hidden' cropping outline */
  /* padding: ${OUTLINE_DISTANCE}; */

  > ${StyledLink} {
    ${TYPE_WEIGHT_BOLD}
  }

  > a {
    ${onFocusOutline(true, true)}
  }

  /* Target child span, as links render as spans when active */
  > span {
    ${insetUnderline(UNDERLINE_H3, false)};
  }
`;

export const ContentTop = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: ${SPACING_L};
  grid-row-gap: ${SPACING_S};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    display: grid;
    grid-template-columns: repeat(2, ${gridGutters(8)});
    grid-column-gap: ${gridGutters(2)};
  }

  @media ${QUERY_GREATER_THAN_TABLET} {
    grid-template-columns: repeat(2, ${gridGutters(6)});
  }
`;

export const ContentBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  @media ${QUERY_GREATER_THAN_MOBILE} {
    align-items: center;
  }
`;

export const ContentBottomLinkWrapper = styled(motion.div)`
  > ${StyledLink} {
    ${TYPE_SIZE_SANS_REGULAR};
  }

  /* Only show hover on links */
  > a {
    transition: color ${TRANSITION_SPEED_FAST}s ${TRANSITION_EASE_CUBIC};

    &:hover {
      color: ${COLOR_ORANGE};
    }

    ${onFocusOutline()}
  }
`;

const CONTENT_BOTTOM_BASE = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  row-gap: ${SPACING_S};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    flex-direction: row;
    column-gap: ${gridGutters(0.5)};
  }
`;

export const ContentBottomLinksWrapper = styled.div`
  ${CONTENT_BOTTOM_BASE}
`;

export const StyledSocials = styled(Socials)`
  ${CONTENT_BOTTOM_BASE}
`;
