import {pxToRem} from '@/theme/grid';

// SPACINGS

/** 5px in REM. */
export const SPACING_XXS = pxToRem(5);

/** -5px in REM. */
export const SPACING_XXS_NEGATIVE = pxToRem(-5);

/** 10px in REM. */
export const SPACING_XS = pxToRem(10);

/** -10px in REM. */
export const SPACING_XS_NEGATIVE = pxToRem(-10);

/** 15px in REM. */
export const SPACING_S = pxToRem(15);

/** 25px in REM. */
export const SPACING_M = pxToRem(25);

/** -25px in REM. */
export const SPACING_M_NEGATIVE = pxToRem(-25);

/** 40px in REM. */
export const SPACING_L = pxToRem(40);

/** -40px in REM. */
export const SPACING_L_NEGATIVE = pxToRem(-40);

/** 70 */
export const SPACING_XL_VALUE = 70;

/** 70px in REM. */
export const SPACING_XL = pxToRem(SPACING_XL_VALUE);

/** -70px in REM. */
export const SPACING_XL_NEGATIVE = pxToRem(-SPACING_XL_VALUE);

/** 110px in REM. */
export const SPACING_XXL = pxToRem(110);

/** -110px in REM. */
export const SPACING_XXL_NEGATIVE = pxToRem(-110);

/** 110px without PX fallback. */
export const SPACING_XXL_WITHOUT_MIN = pxToRem(110, false);
