import {useCursor} from '@/contexts/cursor';

import {TooltipWrapper} from './styles';

const Tooltip = () => {
  const {tooltipComponent} = useCursor();

  return <TooltipWrapper>{tooltipComponent}</TooltipWrapper>;
};

export default Tooltip;
