import styled, {css} from 'styled-components';

import Link from '@/components/Link';
import {onFocusOutline, BORDER_RADIUS_SMALL} from '@/theme/borders';
import {
  COLOR_BLACK,
  COLOR_BLACK_20,
  COLOR_ORANGE,
  COLOR_WHITE,
} from '@/theme/colors';
import {gridGutters, pxToRem} from '@/theme/grid';
import {QUERY_GREATER_THAN_MOBILE} from '@/theme/mediaQueries';
import {
  SPACING_M,
  SPACING_S,
  SPACING_XS,
  SPACING_XS_NEGATIVE,
  SPACING_XXS_NEGATIVE,
} from '@/theme/spacings';
import {
  TRANSITION_EASE_CUBIC,
  TRANSITION_SPEED_FAST,
} from '@/theme/transitions';
import {
  TYPE_FONT_SANS,
  TYPE_SIZE_SANS_REGULAR,
  TYPE_SIZE_SANS_SMALL,
  TYPE_WEIGHT_REGULAR,
  TYPE_WEIGHT_SEMI_BOLD,
} from '@/theme/type';

import {IconPosition} from '.';
import {ICON_WRAPPER_CLASS} from '../icons/IconWrapper/styles';

interface Props {
  $active?: boolean;
  $secondary?: boolean;
  $small?: boolean;
  $round?: boolean;
  $iconPosition?: IconPosition;
  $animateArrow?: boolean;
  $centerWithinContainer?: boolean;
}

const STANDARD_ACTIVE = css<Props>`
  ${({$secondary}) => css`
    background: ${!$secondary && COLOR_ORANGE};
    border-color: ${$secondary && COLOR_BLACK};
  `}

  ${({$animateArrow, $iconPosition}) =>
    $animateArrow &&
    $iconPosition &&
    css`
      > .${ICON_WRAPPER_CLASS} > svg {
        /* Arrow has rotation pre-applied, so translate + keep rotation */
        transform: translateX(
            ${$iconPosition === 'right' ? SPACING_XS : SPACING_XS_NEGATIVE}
          )
          rotate(${$iconPosition === 'right' ? 90 : 270}deg);
      }
    `};
`;

const STANDARD_BASE = css<Props>`
  display: flex;
  align-items: center;
  width: fit-content;
  color: ${COLOR_BLACK};
  white-space: nowrap;

  ${onFocusOutline()}

  > .${ICON_WRAPPER_CLASS} {
    ${({$iconPosition}) =>
      $iconPosition &&
      ($iconPosition === 'right'
        ? css`
            margin-left: ${gridGutters(1)};

            @media ${QUERY_GREATER_THAN_MOBILE} {
              margin-left: ${gridGutters(2)};
            }
          `
        : css`
            margin-right: ${gridGutters(1)};

            @media ${QUERY_GREATER_THAN_MOBILE} {
              margin-right: ${gridGutters(2)};
            }
          `)}

    > svg {
      flex: none;

      ${({$animateArrow, $iconPosition}) =>
        $animateArrow &&
        $iconPosition &&
        css`
          transition: transform ${TRANSITION_SPEED_FAST}s
            ${TRANSITION_EASE_CUBIC};
        `}
    }
  }

  ${({$centerWithinContainer}) =>
    $centerWithinContainer &&
    css`
      margin: 0 auto;
    `}

  ${({$secondary, $small}) =>
    $secondary
      ? css`
          height: ${$small
            ? pxToRem(35, true, 'mobile')
            : pxToRem(50, true, 'mobile')};
          padding: 0 ${$small ? SPACING_S : SPACING_M};
          border-radius: ${BORDER_RADIUS_SMALL};
          background: transparent;
          border: 1px solid ${COLOR_BLACK_20};
          transition: border-color ${TRANSITION_SPEED_FAST}s
            ${TRANSITION_EASE_CUBIC};
          ${$small ? TYPE_SIZE_SANS_SMALL : TYPE_SIZE_SANS_REGULAR}
          ${$small && TYPE_WEIGHT_REGULAR};

          @media ${QUERY_GREATER_THAN_MOBILE} {
            height: ${$small ? pxToRem(35) : pxToRem(50)};
          }
        `
      : css`
          height: ${$small
            ? pxToRem(50, true, 'mobile')
            : pxToRem(70, true, 'mobile')};
          padding: 0 ${SPACING_M};
          background: ${COLOR_WHITE};
          border-radius: ${$small
            ? pxToRem(25, true, 'mobile')
            : pxToRem(35, true, 'mobile')};
          transition: border-color ${TRANSITION_SPEED_FAST}s
              ${TRANSITION_EASE_CUBIC},
            background ${TRANSITION_SPEED_FAST}s ${TRANSITION_EASE_CUBIC};
          ${TYPE_SIZE_SANS_REGULAR}

          @media ${QUERY_GREATER_THAN_MOBILE} {
            height: ${$small ? pxToRem(50) : pxToRem(70)};
            border-radius: ${$small ? pxToRem(25) : pxToRem(35)};
          }
        `}

  ${({$active}) => $active && STANDARD_ACTIVE}
  &:hover:not(:disabled) {
    ${STANDARD_ACTIVE}
  }
`;

const ROUND_ACTIVE = css<Props>`
  background: ${COLOR_ORANGE};

  ${({$animateArrow}) =>
    $animateArrow &&
    css`
      > .${ICON_WRAPPER_CLASS} > svg {
        transform: translateX(${SPACING_XXS_NEGATIVE}) rotate(270deg);
      }
    `};
`;

const ROUND_BASE = css<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 0;
  background: ${COLOR_WHITE};
  width: ${pxToRem(60)};
  height: ${pxToRem(60)};
  flex: none;
  transition: background ${TRANSITION_SPEED_FAST}s ${TRANSITION_EASE_CUBIC};

  ${onFocusOutline()}

  > .${ICON_WRAPPER_CLASS} > svg {
    flex: none;

    ${({$animateArrow}) =>
      $animateArrow &&
      css`
        transition: transform ${TRANSITION_SPEED_FAST}s ${TRANSITION_EASE_CUBIC};
      `}
  }

  ${({$active}) => $active && ROUND_ACTIVE}
  &:hover:not(:disabled) {
    ${ROUND_ACTIVE}
  }
`;

export const StyledButton = styled.button<Props>`
  border: 0;
  ${TYPE_FONT_SANS}
  ${TYPE_WEIGHT_SEMI_BOLD}
  ${({$round}) => ($round ? ROUND_BASE : STANDARD_BASE)}

  &:not(:disabled) {
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.4;
  }
`;

export const StyledLink = styled(Link)<Props>`
  text-decoration: none;
  ${({$round}) => ($round ? ROUND_BASE : STANDARD_BASE)}
`;
