import {useEffect, useMemo, useRef} from 'react';

import {AnimatePresence} from 'framer-motion';
import {useLocomotiveScroll} from 'react-locomotive-scroll';

import Arrow from '@/components/icons/Arrow';
import Drag from '@/components/icons/Drag';
import Pause from '@/components/icons/Pause';
import Play from '@/components/icons/Play';
import {useCursor} from '@/contexts/cursor';
import {
  EASE_ON,
  EASE_OUT,
  TRANSITION_SPEED_RAPID,
  TRANSITION_SPEED_REGULAR,
} from '@/theme/transitions';
import {Direction} from '@/utils/svgrProps';

import {ScrollProperties} from '../LocomotiveScroll';
import Tooltip from '../Tooltip';
import {StyledCursor, CursorWrapper, IconWrapper} from './styles';

export enum CursorType {
  Drag = 'drag',
  ExternalLink = 'externalLink',
  Pause = 'pause',
  Play = 'play',
  ElementTracking = 'elementTracking',
  Next = 'next',
  Back = 'back',
  None = 'none',
}

const CURSOR_SCALE_IN_OUT_ANIMATION = {
  initial: {scale: 0},
  animate: {
    scale: 1,
    transition: {
      duration: TRANSITION_SPEED_REGULAR,
      ease: EASE_ON,
    },
  },
  exit: {
    scale: 0,
    transition: {
      duration: TRANSITION_SPEED_REGULAR,
      ease: EASE_OUT,
    },
  },
};

const ICON_SCALE_IN_OUT_ANIMATION = {
  initial: {scale: 0},
  animate: {
    scale: 1,
    transition: {
      delay: TRANSITION_SPEED_RAPID,
      duration: TRANSITION_SPEED_REGULAR,
      ease: EASE_ON,
    },
  },
};

const Cursor = () => {
  const {type} = useCursor();

  // We have to offset the Y position by the scroll position from Locomotive
  // Scroll. The clientY or pageY would only return values above the fold.
  const {scroll} = useLocomotiveScroll();
  const offsetHeight = useRef<number>(0);
  const cursorWrapperRef = useRef<HTMLDivElement>(null);

  const Icon = useMemo(() => {
    switch (type) {
      case CursorType.Drag:
        return Drag;

      case CursorType.ExternalLink:
        return Arrow;

      case CursorType.Pause:
        return Pause;

      case CursorType.Play:
        return Play;

      case CursorType.Back:
        return Arrow;

      case CursorType.Next:
        return Arrow;

      default:
        return Arrow;
    }
  }, [type]);

  useEffect(() => {
    const handleMousemove = (event: MouseEvent) => {
      if (!cursorWrapperRef.current) return;

      const x = event.pageX;

      // Add offsetHeight to the clientY to get to the final Y position
      const y = event.clientY + offsetHeight.current;

      cursorWrapperRef.current.style.transform = `translate(${x}px, ${y}px)`;
    };

    window.addEventListener('mousemove', handleMousemove);
    return () => window.removeEventListener('mousemove', handleMousemove);
  });

  useEffect(() => {
    if (!scroll) return;

    scroll.on('scroll', ({scroll}: ScrollProperties) => {
      offsetHeight.current = scroll.y;
    });
  });

  const iconProps = type === CursorType.Back ? {direction: Direction.Left} : {};

  return (
    <CursorWrapper ref={cursorWrapperRef}>
      <AnimatePresence>
        {type !== CursorType.None && (
          <>
            {type !== CursorType.ElementTracking && (
              <StyledCursor {...CURSOR_SCALE_IN_OUT_ANIMATION}>
                <AnimatePresence exitBeforeEnter>
                  <IconWrapper key={type} {...ICON_SCALE_IN_OUT_ANIMATION}>
                    <Icon {...iconProps} />
                  </IconWrapper>
                </AnimatePresence>
              </StyledCursor>
            )}
            <Tooltip />
          </>
        )}
      </AnimatePresence>
    </CursorWrapper>
  );
};

export default Cursor;
