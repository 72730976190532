import {useCallback, useEffect, useRef, useState} from 'react';

import {AnimatePresence} from 'framer-motion';
import {useLocomotiveScroll} from 'react-locomotive-scroll';

import {ScrollProperties} from '@/components/LocomotiveScroll';
import {eventBackToTop} from '@/config/tracking';
import {theme} from '@/content/cms/types';
import {useNav} from '@/contexts/nav';
import useClientMediaQuery from '@/hooks/useClientMediaQuery';
import {QUERY_GREATER_THAN_TABLET} from '@/theme/mediaQueries';
import {EASE_ON, EASE_OUT, TRANSITION_SPEED_REGULAR} from '@/theme/transitions';

import {JourneyBackToTopWrapper, StyledButton} from './styles';

interface Props {
  theme: theme;
  label: string;
}

const SCROLL_DELTA = 5;

const JourneyBackToTop = ({theme, label}: Props) => {
  const {scroll} = useLocomotiveScroll();
  const {subNavScrolling} = useNav();
  const previousScrollYRef = useRef(0);
  const currentScrollYRef = useRef(0);
  const [showing, setShowing] = useState(false);
  const desktop = useClientMediaQuery(QUERY_GREATER_THAN_TABLET);

  const handleScroll = useCallback(
    ({scroll}: ScrollProperties) => {
      previousScrollYRef.current = currentScrollYRef.current;
      currentScrollYRef.current = scroll.y;

      // If user only scrolled a small amount, ignore
      const scrollDistance = Math.abs(
        previousScrollYRef.current - currentScrollYRef.current,
      );
      if (scrollDistance <= SCROLL_DELTA || subNavScrolling.current) return;

      setShowing(previousScrollYRef.current >= currentScrollYRef.current);
    },
    [subNavScrolling, setShowing],
  );

  useEffect(() => {
    if (!scroll) return;
    scroll.on('scroll', handleScroll);
  }, [scroll, handleScroll]);

  return (
    <AnimatePresence>
      {!desktop && showing && (
        <JourneyBackToTopWrapper
          initial={{y: '100%'}}
          animate={{
            y: 0,
            transition: {ease: EASE_ON, duration: TRANSITION_SPEED_REGULAR},
          }}
          exit={{
            y: '100%',
            transition: {
              ease: EASE_OUT,
              duration: TRANSITION_SPEED_REGULAR,
            },
          }}
        >
          <StyledButton
            onClick={() => scroll.scrollTo(0)}
            tracking={eventBackToTop('Mobile')}
            $theme={theme}
            small
          >
            {label}
          </StyledButton>
        </JourneyBackToTopWrapper>
      )}
    </AnimatePresence>
  );
};

export default JourneyBackToTop;
