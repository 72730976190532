import Vector2 from './math/Vector2';

const getAspectRatioVector2FromString = (aspectRatio: string) => {
  const splitRatio = aspectRatio.split(':');
  if (
    splitRatio.length !== 2 ||
    isNaN(parseFloat(splitRatio[0])) ||
    isNaN(parseFloat(splitRatio[1]))
  ) {
    throw new Error('Incorrect aspect ratio format. Example: 16:9.');
  }
  const [x, y] = splitRatio;
  return new Vector2(parseFloat(x), parseFloat(y));
};

export default getAspectRatioVector2FromString;
