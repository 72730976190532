import {TOPIC_ALL} from '@/content/cms';
import {Topic, ArticleListItem} from '@/content/cms/types';
import {Job} from '@/content/jobs/types';

export const SLUG_HOME = 'index';
export const SLUG_JOURNEY = 'journey';
export const SLUG_TEAM = 'team';
export const SLUG_TECHNOLOGY = 'technology';
export const SLUG_AT_EVERYDAY_ROBOTS = 'at-everyday-robots';
export const SLUG_THINKING = 'thinking';
export const SLUG_ARCHIVE = 'archive';
export const SLUG_JOIN_US = 'join-us';
export const SLUG_JOB = 'job';
export const SLUG_VISION = 'vision';
export const SLUG_404 = 'four-zero-four';

export const getSlugPath = (slug: string) =>
  slug === SLUG_HOME ? '/' : `/${slug}`;

export const getArchivedArticlesPath = (
  topic?: Topic | null,
  page?: number,
) => {
  if ((!topic || topic.slug === TOPIC_ALL.slug) && (!page || page <= 1)) {
    return `/${SLUG_THINKING}/${SLUG_ARCHIVE}`;
  }

  if (!topic) topic = TOPIC_ALL;

  const base = `/${SLUG_THINKING}/${SLUG_ARCHIVE}/${topic.slug}`;
  return page && page > 1 ? `${base}/${page}` : base;
};

export const getArticlePath = (article: ArticleListItem) =>
  `/${SLUG_THINKING}/${article.slug}`;

export const getJobPath = (job: Job) =>
  `/${SLUG_JOIN_US}/${SLUG_JOB}?id=${job.id}`;
