import {motion} from 'framer-motion';
import styled, {css} from 'styled-components';

import Link from '@/components/Link';
import {theme} from '@/content/cms/types';
import {onFocusOutline} from '@/theme/borders';
import {
  COLOR_BLACK,
  COLOR_BLACK_20,
  COLOR_WHITE,
  COLOR_WHITE_20,
} from '@/theme/colors';
import {pxToRem} from '@/theme/grid';
import {
  TRANSITION_EASE_CUBIC,
  TRANSITION_SPEED_RAPID,
} from '@/theme/transitions';

import {ICON_WRAPPER_CLASS} from '../icons/IconWrapper/styles';

interface Props {
  $theme: theme;
  $targetStrokeOverPath?: boolean;
  $small?: boolean;
}

export const StyledSocial = styled(motion(Link))<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({$small}) => ($small ? pxToRem(50) : pxToRem(60))};
  height: ${({$small}) => ($small ? pxToRem(50) : pxToRem(60))};
  border: 1px solid
    ${({$theme}) => ($theme === 'dark' ? COLOR_BLACK_20 : COLOR_WHITE_20)};
  border-radius: 50%;
  cursor: pointer;
  transition: border ${TRANSITION_SPEED_RAPID}s ${TRANSITION_EASE_CUBIC};

  ${onFocusOutline()}

  > .${ICON_WRAPPER_CLASS} > svg > path {
    ${({$targetStrokeOverPath, $theme}) =>
      $targetStrokeOverPath
        ? css`
            stroke: ${$theme === 'dark' ? COLOR_BLACK : COLOR_WHITE};
          `
        : css`
            fill: ${$theme === 'dark' ? COLOR_BLACK : COLOR_WHITE};
          `}
  }

  &:hover {
    border-color: ${({$theme}) =>
      $theme === 'dark' ? COLOR_BLACK : COLOR_WHITE};
  }
`;
