import styled from 'styled-components';

import {PREVENT_CLICKS} from '@/theme/pointerEvents';
import {Z_INDEX_CURSOR} from '@/theme/zIndex';

export const TooltipWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transform-origin: 50% 50%;
  z-index: ${Z_INDEX_CURSOR};
  ${PREVENT_CLICKS}
  order: -1;
`;
