// ALPHA

const ALPHA_10 = '1a';
const ALPHA_20 = '33';
const ALPHA_30 = '4d';
const ALPHA_50 = '80';
const ALPHA_60 = '99';
const ALPHA_70 = 'b3';
const ALPHA_90 = 'e6';

// COLORS

export const COLOR_BLACK = '#000000';
export const COLOR_BLACK_10 = `${COLOR_BLACK}${ALPHA_10}`;
export const COLOR_BLACK_20 = `${COLOR_BLACK}${ALPHA_20}`;
export const COLOR_BLACK_60 = `${COLOR_BLACK}${ALPHA_60}`;
export const COLOR_BLACK_90 = `${COLOR_BLACK}${ALPHA_90}`;

export const COLOR_ALMOST_BLACK = '#11100e';
export const COLOR_ALMOST_BLACK_70 = `${COLOR_ALMOST_BLACK}${ALPHA_70}`;

export const COLOR_OFF_BLACK = '#151519';

export const COLOR_DARK = '#262626';
export const COLOR_DARK_60 = `${COLOR_DARK}${ALPHA_60}`;

export const COLOR_DARK_GREY = '#666666';

export const COLOR_GREY = '#8a8a8c';

export const COLOR_LIGHT_GREY = '#d5d1cf';

export const COLOR_LIGHT = '#f4f1f0';

export const COLOR_WHITE = '#ffffff';
export const COLOR_WHITE_10 = `${COLOR_WHITE}${ALPHA_10}`;
export const COLOR_WHITE_20 = `${COLOR_WHITE}${ALPHA_20}`;
export const COLOR_WHITE_50 = `${COLOR_WHITE}${ALPHA_50}`;
export const COLOR_WHITE_70 = `${COLOR_WHITE}${ALPHA_70}`;

export const COLOR_LATTE = '#ede8e6';
export const COLOR_OFF_LATTE = '#e3dedc';

export const COLOR_ORANGE = `#ffad00`;

export const COLOR_BLUE = '#85cae5';

export const COLOR_GREEN = '#72bf4c';

export const COLOR_RED = '#ee4747';
export const COLOR_RED_30 = `#ee4747${ALPHA_30}`;
export const COLOR_RED_50 = `#ee4747${ALPHA_50}`;

// HIGHLIGHT

export const COLOR_HIGHLIGHT = `${COLOR_ORANGE}${ALPHA_30}`;
