import React, {MutableRefObject, useRef, useState} from 'react';

import {Header, theme} from '@/content/cms/types';
import useToggle from '@/hooks/useToggle';

export interface ChapterTitle {
  title: string;
  era?: string;
  chapter: number;
}

interface Props {
  header?: Header;
  setHeader: (header?: Header) => void;
  label?: string;
  setLabel: (string?: string) => void;
  opened: boolean;
  toggle: () => void;
  atTopOfPage: boolean;
  setAtTopOfPage: (value: boolean) => void;
  navShowing: boolean;
  setNavShowing: (value: boolean) => void;
  subNavShowing: boolean;
  toggleSubNav: () => void;
  setSubNavShowing: (value: boolean) => void;
  currentChapter: number;
  setCurrentChapter: (value: number) => void;
  chapterTitles: ChapterTitle[];
  setChapterTitles: (titles: ChapterTitle[]) => void;
  subNavScrolling: MutableRefObject<boolean>;
  subNavTheme: theme;
  setSubNavTheme: (value: theme) => void;
}

const NavContext = React.createContext<Props>({} as Props);

const NavProvider: React.FC = ({children}) => {
  const [header, setHeader] = useState<Header>();
  const [label, setLabel] = useState<string>();
  const [opened, toggle] = useToggle();
  const [atTopOfPage, setAtTopOfPage] = useState(false);
  const [navShowing, setNavShowing] = useState(true);
  const [subNavShowing, toggleSubNav, setSubNavShowing] = useToggle();
  const [currentChapter, setCurrentChapter] = useState(0);
  const [subNavTheme, setSubNavTheme] = useState('dark' as theme);

  const [chapterTitles, setChapterTitles] = useState<ChapterTitle[]>([]);
  const subNavScrolling = useRef(false);

  return (
    <NavContext.Provider
      value={{
        header,
        setHeader,
        label,
        setLabel,
        opened,
        toggle,
        atTopOfPage,
        setAtTopOfPage,
        navShowing,
        setNavShowing,
        subNavShowing,
        toggleSubNav,
        setSubNavShowing,
        currentChapter,
        setCurrentChapter,
        chapterTitles,
        setChapterTitles,
        subNavScrolling,
        subNavTheme,
        setSubNavTheme,
      }}
    >
      {children}
    </NavContext.Provider>
  );
};

const useNav = () => React.useContext(NavContext);

export {NavProvider, useNav};
