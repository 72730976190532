import {sortPublishDateDescending} from '@/utils/date';

import fetchData from './fetchData';
import {
  QUERY_GLOBALS,
  QUERY_ARTICLES,
  QUERY_ARTICLE,
  QUERY_TOPICS,
  QUERY_PAGE_PAYLOAD_ONE,
  QUERY_PAGE_PAYLOAD_TWO,
  QUERY_PAGED_ARTICLES,
  QUERY_TOPIC_PAGED_ARTICLES,
  QUERY_ARTICLES_COUNT,
  QUERY_TOPIC_ARTICLES_COUNT,
} from './queries';
import {
  Page,
  Article,
  Topic,
  PagedItems,
  ArticleListItem,
  Globals,
  PageType,
} from './types';

export const TOPIC_ALL = {
  title: 'All',
  slug: 'all',
};

interface PageWithDescription {
  metaDescription: string;
}

export const overrideMeta = (page: PageWithDescription, globals: Globals) => {
  if (page.metaDescription) {
    globals.siteDescription = page.metaDescription;
  }
};

export const getGlobals = async (previewToken?: string) => {
  const response = await fetchData({query: QUERY_GLOBALS, previewToken});
  return {
    ...response.globalsCollection.items[0],
    preview: !!previewToken,
  } as Globals;
};

export const getPage = async <T = PageType>(
  pageSlug: string,
  previewToken?: string,
) => {
  const payloadOne = await fetchData({
    query: QUERY_PAGE_PAYLOAD_ONE,
    variables: {slug: pageSlug},
    previewToken,
  });
  const payloadTwo = await fetchData({
    query: QUERY_PAGE_PAYLOAD_TWO,
    variables: {slug: pageSlug},
    previewToken,
  });

  const joinedBlocksCollection = [
    ...payloadOne.pageCollection.items[0].blocksCollection.items,
    ...payloadTwo.pageCollection.items[0].blocksCollection.items,
  ];

  return {
    ...payloadOne.pageCollection.items[0],
    blocksCollection: {items: joinedBlocksCollection},
  } as Page<T>;
};

export const getArticle = async (
  articleSlug: string,
  previewToken?: string,
) => {
  const response = await fetchData({
    query: QUERY_ARTICLE,
    variables: {slug: articleSlug},
    previewToken,
  });
  return response.articleCollection.items[0] as Article;
};

export const getArticles = async (
  limit?: number,
  relatedTopicSlug?: string,
  articleSlugsToIgnore?: string[],
  previewToken?: string,
) => {
  const response = await fetchData({
    query: QUERY_ARTICLES,
    variables: {
      limit,
      relatedTopicSlug:
        relatedTopicSlug !== TOPIC_ALL.slug ? relatedTopicSlug : undefined,
      slugsToIgnore: articleSlugsToIgnore || undefined,
    },
    previewToken,
  });
  return response.articleCollection.items as ArticleListItem[];
};

export const getPagedArticles = async (
  pageSize: number,
  topicSlug: string,
  page = 0,
  previewToken?: string,
) => {
  // Get pages articles within topic
  if (topicSlug && topicSlug !== TOPIC_ALL.slug) {
    const response = await fetchData({
      query: QUERY_TOPIC_PAGED_ARTICLES,
      variables: {
        slug: topicSlug,
        skip: pageSize * page,
        limit: pageSize,
      },
      previewToken,
    });

    const articleCollection =
      response.topicCollection.items[0].linkedFrom.articleCollection;

    const items = sortPublishDateDescending(
      articleCollection.items as ArticleListItem[],
    );
    const total = articleCollection.total;

    return {items, total} as PagedItems<ArticleListItem>;
  }

  // Else, get pages articles ignoring topic
  else {
    const response = await fetchData({
      query: QUERY_PAGED_ARTICLES,
      variables: {
        skip: pageSize * page,
        limit: pageSize,
      },
      previewToken,
    });

    const items = response.articleCollection.items as ArticleListItem[];
    const total = response.articleCollection.total;

    return {items, total} as PagedItems<ArticleListItem>;
  }
};

export const getArticlesCount = async (
  topicSlug: string,
  previewToken?: string,
) => {
  // Get articles count within topic
  if (topicSlug && topicSlug !== TOPIC_ALL.slug) {
    const response = await fetchData({
      query: QUERY_TOPIC_ARTICLES_COUNT,
      variables: {
        slug: topicSlug,
      },
      previewToken,
    });
    const total =
      response.topicCollection.items[0].linkedFrom.articleCollection.total;
    return total as number;
  }

  // Else, get articles count ignoring topic
  else {
    const response = await fetchData({
      query: QUERY_ARTICLES_COUNT,
      previewToken,
    });
    return response.articleCollection.total;
  }
};

/**
 * Used to return an array of article topics.
 *
 * @param includeAllTopic Set to `true` to include `All` topic as part of topics
 * list, or `false` to ignore and only return topics from CMS.
 * Defaults to `true`.
 * @param includeTopicsWithoutArticles Used to return only topics that
 * have existing articles (`true`), or all topics. Defaults to `false`.
 */
export const getTopics = async (
  includeAllTopic = true,
  includeTopicsWithoutArticles = false,
  previewToken?: string,
) => {
  const response = await fetchData({query: QUERY_TOPICS, previewToken});

  const topics = (
    includeAllTopic
      ? [TOPIC_ALL, ...response.topicCollection.items]
      : response.topicCollection.items
  ) as Topic[];

  if (includeTopicsWithoutArticles) return topics;

  const topicsWithArticles: Topic[] = includeAllTopic ? [TOPIC_ALL] : [];
  for (const topic of topics) {
    if (topic.slug !== TOPIC_ALL.slug) {
      const count = await getArticlesCount(topic.slug);
      if (count > 0) topicsWithArticles.push(topic);
    }
  }

  return topicsWithArticles;
};
