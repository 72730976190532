import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from 'react';

import {CursorType} from '@/components/Cursor';

interface Props {
  type: CursorType;
  setType: Dispatch<SetStateAction<CursorType>>;
  tooltipComponent: JSX.Element;
  setTooltipComponent: Dispatch<SetStateAction<JSX.Element>>;
}

const CursorContext = createContext<Props>({} as Props);

const CursorProvider: React.FC = ({children}) => {
  const [type, setType] = useState<CursorType>(CursorType.None);
  const [tooltipComponent, setTooltipComponent] = useState(<></>);

  return (
    <CursorContext.Provider
      value={{
        type,
        setType,
        tooltipComponent,
        setTooltipComponent,
      }}
    >
      {children}
    </CursorContext.Provider>
  );
};

const useCursor = () => useContext(CursorContext);

export {CursorProvider, useCursor};
