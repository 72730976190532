import React from 'react';

import {trackEvent} from '@phntms/react-gtm';
import {AnimatePresence} from 'framer-motion';
import {useLocomotiveScroll} from 'react-locomotive-scroll';

import {
  eventSubNavClickLink,
  eventSubNavToggle,
  eventBackToTop,
} from '@/config/tracking';
import {useNav} from '@/contexts/nav';
import useToggle from '@/hooks/useToggle';
import {EASE_ON, EASE_OUT, TRANSITION_SPEED_REGULAR} from '@/theme/transitions';
import {Direction} from '@/utils/svgrProps';

import JourneyBackToTop from '../JourneyBackToTop';
import {
  JourneySubNavWrapper,
  ChapterTitle,
  StyledAngle,
  ProgressCircle,
  ProgressBar,
  ChapterItem,
  ProgressCircleInner,
  ChapterYears,
  ChapterText,
  ProgressLineMask,
  MobileDropdownWrapper,
  DropdownMenu,
  CurrentItem,
  Toggle,
  BackToTopButton,
  DropdownItem,
  DropdownTitle,
  DropdownEra,
  DropdownBar,
  DropdownItemWrapper,
  ChapterItems,
} from './styles';

const TRANSLATE_IN_OUT_ANIMATION = {
  initial: {y: '-110%'},
  animate: {
    y: '0',
    transition: {
      duration: TRANSITION_SPEED_REGULAR,
      ease: EASE_ON,
    },
  },
  exit: {
    y: '-110%',
    transition: {
      duration: TRANSITION_SPEED_REGULAR,
      ease: EASE_OUT,
    },
  },
};

const getProgressPercentageByChapter = (
  currentChapter: number,
  totalChapters: number,
) => (currentChapter / totalChapters) * 100 - (100 / totalChapters) * 0.5;

/**
 * Used to ensure that the timeout in the scrollTo below does not conflict with
 * new nav selections.
 */
let scrollCounter = 0;

const JourneySubNav = () => {
  const {
    header,
    subNavShowing,
    chapterTitles,
    currentChapter,
    setCurrentChapter,
    subNavScrolling,
    setNavShowing,
    subNavTheme,
  } = useNav();
  const [opened, toggleOpened] = useToggle();
  const {scroll} = useLocomotiveScroll();

  const handleClick = (target: number) => {
    scrollCounter++;
    const currentScroll = scrollCounter;

    subNavScrolling.current = true;
    setNavShowing(false);

    scroll.scrollTo(`#journeyChapter${target}`, {
      offset: '-20px',
      callback: () => {
        // need to wait for lerp to stabilize
        setTimeout(() => {
          if (scrollCounter == currentScroll) {
            subNavScrolling.current = false;
            setNavShowing(false);
          }
        }, 1000);
      },
    });
    setCurrentChapter(target);
  };

  const currentItem =
    chapterTitles[currentChapter > 0 ? currentChapter - 1 : 0];

  if (!header) return null;

  return (
    <>
      <AnimatePresence>
        {subNavShowing && (
          <JourneySubNavWrapper
            {...TRANSLATE_IN_OUT_ANIMATION}
            $theme={subNavTheme}
          >
            <MobileDropdownWrapper>
              <DropdownBar $theme={subNavTheme}>
                <CurrentItem>
                  <DropdownTitle>{currentItem.title}</DropdownTitle>
                  {currentItem.era && (
                    <DropdownEra> {currentItem.era}</DropdownEra>
                  )}
                </CurrentItem>
                <Toggle
                  onClick={() => {
                    toggleOpened();
                    trackEvent({
                      data: eventSubNavToggle(!opened ? 'Opened' : 'Closed'),
                    });
                  }}
                >
                  <StyledAngle
                    direction={opened ? Direction.Down : Direction.Up}
                    theme={subNavTheme}
                  />
                </Toggle>
              </DropdownBar>
              <AnimatePresence>
                {opened && (
                  <DropdownMenu
                    {...TRANSLATE_IN_OUT_ANIMATION}
                    $progress={getProgressPercentageByChapter(
                      currentChapter,
                      chapterTitles.length,
                    )}
                    $theme={subNavTheme}
                  >
                    {chapterTitles.map(({title, era}, index) => (
                      <DropdownItemWrapper
                        key={index}
                        $active={index <= currentChapter - 1}
                        aria-labelledby={`dropdownNav${index}`}
                        onClick={() => {
                          handleClick(index + 1);
                          toggleOpened();
                          trackEvent({data: eventSubNavClickLink(title)});
                        }}
                      >
                        <ProgressLineMask $theme={subNavTheme}>
                          <ProgressCircle>
                            <ProgressCircleInner />
                          </ProgressCircle>
                        </ProgressLineMask>
                        {index === chapterTitles.length - 1 && (
                          <ProgressLineMask
                            $lastIndexMask
                            $theme={subNavTheme}
                          />
                        )}
                        <DropdownItem $active={opened} $theme={subNavTheme}>
                          <DropdownTitle id={`dropdownNav${index}`}>
                            {title}
                          </DropdownTitle>
                          {era && <DropdownEra>{era}</DropdownEra>}
                        </DropdownItem>
                      </DropdownItemWrapper>
                    ))}
                  </DropdownMenu>
                )}
              </AnimatePresence>
            </MobileDropdownWrapper>
            <ProgressBar>
              <BackToTopButton
                onClick={() => {
                  scroll.scrollTo(0);
                  trackEvent({data: eventBackToTop('Desktop')});
                }}
                $theme={subNavTheme}
              >
                {header.subNavBackToTopLabel}
              </BackToTopButton>
              <ChapterItems
                $progress={getProgressPercentageByChapter(
                  currentChapter,
                  chapterTitles.length,
                )}
              >
                {chapterTitles.map(({title, era}, index) => (
                  <ChapterItem
                    key={index}
                    $active={index === currentChapter - 1}
                    $hasBeenActive={index <= currentChapter - 2}
                    aria-labelledby={`subNav${index}`}
                    onClick={() => {
                      handleClick(index + 1);
                      trackEvent({data: eventSubNavClickLink(title)});
                    }}
                  >
                    <ChapterText
                      $alwaysVisible={index === chapterTitles.length - 1}
                    >
                      <ChapterTitle $theme={subNavTheme} id={`subNav${index}`}>
                        {title}
                      </ChapterTitle>
                      {era && (
                        <ChapterYears $theme={subNavTheme} sansSize="small">
                          {era}
                        </ChapterYears>
                      )}
                    </ChapterText>
                    <ProgressLineMask $theme={subNavTheme}>
                      <ProgressCircle>
                        <ProgressCircleInner />
                      </ProgressCircle>
                    </ProgressLineMask>
                  </ChapterItem>
                ))}
              </ChapterItems>
            </ProgressBar>
          </JourneySubNavWrapper>
        )}
      </AnimatePresence>
      {subNavShowing && (
        <JourneyBackToTop
          theme={subNavTheme}
          label={header.subNavBackToTopLabel}
        />
      )}
    </>
  );
};

export default JourneySubNav;
