import styled, {css, keyframes} from 'styled-components';

import {BORDER_RADIUS_SMALL} from '@/theme/borders';
import {aspectRatio} from '@/theme/mediaQueries';
import {TRANSITION_EASE_ON, TRANSITION_SPEED_SLOW} from '@/theme/transitions';
import Vector2 from '@/utils/math/Vector2';

export type objectFit = 'cover' | 'contain';

const FADE_IN = keyframes`
  to {
    opacity: 1;
  }
`;

export const LAZY_LOAD = css<MediaProps>`
  ${({$lazy}) =>
    $lazy &&
    css`
      opacity: 0;

      &[data-loaded='true'] {
        animation: ${FADE_IN} ${TRANSITION_SPEED_SLOW}s ${TRANSITION_EASE_ON}
          forwards;
      }
    `}
`;

export const StyledPicture = styled.picture<MediaProps>`
  ${LAZY_LOAD}
`;

export const StyledVideo = styled.video<MediaProps>`
  width: 100%;
  object-fit: cover;
  height: 100%;
  ${LAZY_LOAD}
`;

export interface MediaProps {
  $aspectRatio?: Vector2;
  $objectFit?: objectFit;
  $lazy?: boolean;
  $hasInnerLocomotive?: boolean;
}

export const MediaWrapper = styled.div<MediaProps>`
  display: flex;
  overflow: hidden;
  border-radius: ${BORDER_RADIUS_SMALL};

  ${({$aspectRatio, $objectFit}) =>
    $aspectRatio
      ? css`
          ${aspectRatio($aspectRatio)};
          position: relative;

          > ${StyledPicture}, > ${StyledVideo} {
            > * {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              object-fit: ${$objectFit || 'cover'};
              border-radius: ${BORDER_RADIUS_SMALL};
            }
          }
        `
      : css`
          > ${StyledPicture}, > ${StyledVideo} {
            > * {
              width: 100%;
              height: 100%;
              border-radius: ${BORDER_RADIUS_SMALL};

              ${$objectFit &&
              css`
                object-fit: ${$objectFit};
              `}
            }
          }
        `}

  ${({$hasInnerLocomotive}) =>
    $hasInnerLocomotive &&
    css`
      > ${StyledPicture}, > ${StyledVideo} {
        > * {
          border-radius: ${BORDER_RADIUS_SMALL};
        }
      }
    `}
`;
