import {css} from 'styled-components';

import {COLOR_BLACK, COLOR_ORANGE} from './colors';
import {TRANSITION_EASE_CUBIC, TRANSITION_SPEED_REGULAR} from './transitions';

const OUTLINE_DISTANCE_INSET = '-1px';
export const OUTLINE_DISTANCE = '4px';

// TABBING OUTLINES

const outline = (inset: boolean) => css`
  outline: 1px solid ${COLOR_ORANGE};
  outline-offset: ${inset ? OUTLINE_DISTANCE_INSET : OUTLINE_DISTANCE};
`;

/**
 * Used to add a custom focus outline matching content border radius.
 *
 * @param applyFocus Used to apply outline within `&:focus` wrap or not.
 * Defaults to `true`. **Note**: If applying custom focus, make sure to use
 * accompanying `&:not(:focus-visible)` to reset `outline` if cursor.
 * @param inset Used to apply outline inset -1px within element. Only to be used
 * if element has `overflow: hidden`, where normal offset would be hidden.
 */
export const onFocusOutline = (applyFocus = true, inset = false) =>
  applyFocus
    ? css`
        &:focus {
          ${outline(inset)}

          /* If cursor, don't show outline */
          &:not(:focus-visible) {
            outline: 0;
          }
        }
      `
    : outline(inset);

// UNDERLINES

/**
 * Used to add an underline on hover.
 *
 * @param y Underline distance from text, defaults to 1. Where anything less
 * than 1 will bring line closer to text.
 *
 * **Note**: Greater than 1 doesn't work.
 */
export const onHoverUnderline = (y = 1) => {
  const yPercentage = `${y * 100}%`;
  return css`
    display: inline;
    background: linear-gradient(${COLOR_BLACK}, ${COLOR_BLACK});
    background-size: 0 1px, 100% 1px;

    /* Left to right */
    background-position: 0 ${yPercentage}, ${yPercentage} ${yPercentage};
    background-repeat: no-repeat;
    transition: background-size ${TRANSITION_SPEED_REGULAR}s
      ${TRANSITION_EASE_CUBIC};

    &:hover {
      background-size: 100% 1px, 0 1px;
    }
  `;
};

// BORDER RADIUS's

// 2px
export const BORDER_RADIUS_EXTRA_SMALL = '2px';

// 4px
export const BORDER_RADIUS_SMALL = '4px';

// 18px
export const BORDER_RADIUS_MEDIUM = '18px';

// 32px
export const BORDER_RADIUS_LARGE = '2rem';

export const BORDER_RADIUS_EXTRA_LARGE = '100px';
