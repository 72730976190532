import compress from 'graphql-query-compress';
import {GraphQLClient} from 'graphql-request';
import {RateLimiter} from 'limiter';

const SPACE_ID = process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID;

const ENVIRONMENT = process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT;
const GRAPHQL_URL_BASE = `https://graphql.contentful.com/content/v1/spaces/${SPACE_ID}`;
const GRAPHQL_URL = ENVIRONMENT
  ? `${GRAPHQL_URL_BASE}/environments/${ENVIRONMENT}`
  : GRAPHQL_URL_BASE;

const graphQLClient = new GraphQLClient(GRAPHQL_URL);

// Note: Will need to be 20 if using content preview API
const limiter = new RateLimiter({tokensPerInterval: 60, interval: 'second'});
const previewLimiter = new RateLimiter({
  tokensPerInterval: 20,
  interval: 'second',
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fetchData: any = async ({
  query,
  variables,
  previewToken,
}: {
  query: string;
  variables?: Record<string, string | number | boolean | undefined>;
  previewToken?: string;
}) => {
  const rateLimiter = previewToken ? previewLimiter : limiter;
  await rateLimiter.removeTokens(1);

  graphQLClient.setHeader(
    'Authorization',
    `Bearer ${previewToken || process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN}`,
  );

  const compressedQuery = compress(query);

  return await graphQLClient
    .request(compressedQuery, {...variables, preview: !!previewToken})
    .catch((error) => {
      console.log('Contentful fetchData Error:', error);
    });
};

export default fetchData;
