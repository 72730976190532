import {fetchJob, fetchJobs} from './fetchData';
import {Department} from './types';

export const DEPARTMENT_ALL: Department = {id: 0, name: 'All'};

export const getJob = async (jobId: number) => await fetchJob(jobId);

export const getJobs = async () => await fetchJobs();

export const getDepartments = async () => {
  const jobs = await fetchJobs();

  const departments = jobs.reduce((memo, job) => {
    job.departments.forEach((department) => {
      if (!memo.find((d) => d.id === department.id)) memo.push(department);
    });
    return memo;
  }, [] as Department[]);

  return [DEPARTMENT_ALL, ...departments] as Department[];
};
