import {useMemo} from 'react';

import {TargetAndTransition} from 'framer-motion';
import {TransformProperties} from 'framer-motion/types/motion/types';

import {TextRevealWrapper, Mask, Inner} from './styles';

const DEFAULT_STAGGER = 0.1;

export interface TextRevealGroup {
  text: string;
  outerTag?: string;
  innerTag?: string;
}

export type revealType = 'word' | 'character';
export type revealState = 'initial' | 'animate' | 'exit';

interface GroupOfWords {
  words: string[];
  outerTag?: string;
  innerTag?: string;
}

interface GroupOfWordsSplitIntoCharacters {
  wordsSplitIntoCharacters: string[][];
  outerTag?: string;
  innerTag?: string;
}

interface Props {
  className?: string;
  content: TextRevealGroup[] | string;
  reveal: revealType;
  enabledMasking?: boolean;
  /**
   * Add state if you want to control when text reveals. Or, ignore if you want
   * to text to reveal on load / using `<AnimatePresence />`.
   */
  state?: revealState;

  initial: TransformProperties | TargetAndTransition;
  animate: TransformProperties | TargetAndTransition;
  /**
   * To use `exit` prop, `<TextReveal ... />` must be wrapped using
   * `<AnimatePresence />`. To learn more, see
   * [here](https://www.framer.com/docs/examples/#exit-animations).
   */
  exit?: TransformProperties | TargetAndTransition;

  animateStagger: number;
  exitStagger: number;

  animateDelay?: number;
}

const TextReveal = ({
  className,
  content,
  reveal = 'word',
  enabledMasking = false,
  state,

  initial,
  animate,
  exit,

  animateStagger = DEFAULT_STAGGER,
  exitStagger = DEFAULT_STAGGER,

  animateDelay = 0,
}: Props) => {
  // MOTION

  const wrapperVariants = {
    animate: {
      transition: {
        delayChildren: animateDelay,
        staggerChildren: animateStagger,
      },
    },
    exit: {transition: {staggerChildren: exitStagger}},
  };

  const itemVariants = {variants: {initial, animate, exit}};

  // ARIA-LABEL

  const ariaLabel = useMemo(() => {
    return typeof content === 'string'
      ? content
      : content.map((element) => element.text).join('');
  }, [content]);

  // WORDS

  const groupsOfWords: GroupOfWords[] = useMemo(
    () =>
      typeof content === 'string'
        ? [{words: content.split(' ')}]
        : content.map((element) => ({
            words: element.text.trim().split(' '),
            outerTag: element.outerTag,
            innerTag: element.innerTag,
          })),
    [content],
  );

  // CHARACTERS

  const groupsOfCharacters: GroupOfWordsSplitIntoCharacters[] = useMemo(
    () =>
      groupsOfWords.map((group, i) => {
        const wordsSplitIntoCharacters: string[][] = [];

        // Split words into characters
        group.words.forEach((word) =>
          wordsSplitIntoCharacters.push(word.split('')),
        );

        // Add a white space to end of each word, expect final word
        group.words.forEach((_, x) => {
          const lastWord =
            i === groupsOfWords.length - 1 &&
            x === wordsSplitIntoCharacters.length - 1;
          if (!lastWord) wordsSplitIntoCharacters[x].push(' ');
        });

        return {
          wordsSplitIntoCharacters,
          outerTag: group.outerTag,
          interTag: group.innerTag,
        };
      }),
    [groupsOfWords],
  );

  const groups = {
    word: groupsOfWords,
    character: groupsOfCharacters,
  }[reveal];

  return (
    <TextRevealWrapper
      className={className}
      aria-label={ariaLabel}
      initial="initial"
      animate={state ? (state === 'initial' ? undefined : state) : 'animate'}
      exit={state ? undefined : 'exit'}
      variants={wrapperVariants}
    >
      {groups?.map((group, i) => {
        const OuterTag = group.outerTag;
        const InnerTag = group.innerTag || Inner;
        const ariaHidden = !group.outerTag ? {'aria-hidden': true} : undefined;
        let elements: JSX.Element[];

        // Render characters
        if (reveal === 'character') {
          const current = group as GroupOfWordsSplitIntoCharacters;
          elements = current.wordsSplitIntoCharacters.map((word, x) => (
            <Mask
              key={`${i}-${x}`}
              {...ariaHidden}
              $overflowHidden={enabledMasking}
            >
              {word.flat().map((character, z) => {
                const children = {children: character};
                return (
                  <InnerTag
                    key={`${i}-${x}-${z}`}
                    {...itemVariants}
                    {...children}
                  />
                );
              })}
            </Mask>
          ));
        }

        // Else, render words
        else {
          const current = group as GroupOfWords;
          elements = current.words.map((word, x) => {
            const key = `${i}-${x}`;

            const isFinalGroup = i === groups.length - 1;
            const isLastWordInGroup = x === current.words.length - 1;

            const groupStartsWithApostrophe = word === "'" && x === 0;

            let addSpaceAfterWord =
              !(isFinalGroup && isLastWordInGroup) &&
              (word !== "'" || groupStartsWithApostrophe);
            if (addSpaceAfterWord && !isFinalGroup && isLastWordInGroup) {
              const nextCharacter = (groups[i + 1] as GroupOfWords).words[0][0];

              // If next character isn't a comma, full stop or apostrophe, add space
              addSpaceAfterWord =
                nextCharacter !== '.' &&
                nextCharacter !== ',' &&
                nextCharacter !== "'";
            }

            const hasUnderline = !!current.innerTag;
            const underlineTrailingSpace =
              hasUnderline &&
              x !== current.words.length - 1 &&
              addSpaceAfterWord;

            let text = addSpaceAfterWord ? `${word} ` : word;

            const whiteSpaceAfterTag = hasUnderline && !underlineTrailingSpace;

            if (whiteSpaceAfterTag) {
              text = word;
            }

            const children = {children: text};

            return (
              <>
                {enabledMasking ? (
                  <Mask key={key} {...ariaHidden} $overflowHidden>
                    <InnerTag
                      key={`${key}-inner`}
                      {...itemVariants}
                      {...children}
                    />
                  </Mask>
                ) : (
                  <InnerTag
                    key={key}
                    {...ariaHidden}
                    {...itemVariants}
                    {...children}
                  />
                )}
                {whiteSpaceAfterTag && addSpaceAfterWord && ' '}
              </>
            );
          });
        }

        const children = {children: elements};
        return OuterTag ? (
          <OuterTag key={`${i}-tag`} {...children} aria-hidden />
        ) : (
          elements
        );
      })}
    </TextRevealWrapper>
  );
};

export default TextReveal;
