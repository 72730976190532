import {KeyboardEvent, MouseEvent, useState} from 'react';

import {AnimatePresence} from 'framer-motion';

import SkipInitialAnimatePresence from '@/components/SkipInitialAnimatePresence';
import {useAnimationSettings} from '@/contexts/animationSettings';
import {useNav} from '@/contexts/nav';
import {
  EASE_CUBIC,
  EASE_ON,
  EASE_OUT,
  TRANSITION_SPEED_REGULAR,
  TRANSITION_SPEED_SLOW,
} from '@/theme/transitions';

import {
  NavToggleWrapper,
  LabelWrapper,
  StyledTextReveal,
  Toggle,
} from './styles';

const FADE_IN_OUT_ANIMATION = {
  initial: {opacity: 0},
  animate: {opacity: 1},
  exit: {opacity: 0},
  transition: {duration: TRANSITION_SPEED_REGULAR, ease: EASE_CUBIC},
};

const TEXT_REVEAL_ANIMATION = {
  initial: {opacity: 0, y: '50%'},
  animate: {
    opacity: 1,
    y: 0,
    transition: {ease: EASE_ON, duration: TRANSITION_SPEED_REGULAR},
  },
  exit: {
    opacity: 0,
    y: '-50%',
    transition: {
      ease: EASE_OUT,
      duration: TRANSITION_SPEED_REGULAR,
    },
  },
  animateStagger: 0.05,
  exitStagger: 0.05,
};

const TEXT_REVEAL_ANIMATION_ON_LOAD = {
  initial: {opacity: 1, y: 0},
  animate: {
    transition: {ease: EASE_ON, duration: 0},
  },
  exit: {
    transition: {
      ease: EASE_OUT,
      duration: 0,
    },
  },
  animateStagger: 0,
  exitStagger: 0,
};

const SCALE_IN_OUT_ANIMATION = {
  initial: {scale: 0},
  animate: {scale: 1},
  exit: {scale: 0},
  transition: {duration: TRANSITION_SPEED_SLOW, ease: EASE_CUBIC},
};

interface Props {
  closedLabel: string;
  hoveringLabel: string;
  openedLabel: string;
  hidden?: boolean;
  onClick: () => void;
}

const NavToggle = ({
  closedLabel,
  hoveringLabel,
  openedLabel,
  hidden,
  onClick,
}: Props) => {
  const {opened} = useNav();
  const [hovering, setHovering] = useState(false);
  const [focused, setFocused] = useState(false);
  const {enabled} = useAnimationSettings();

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    onClick();
    setHovering(false);
    setFocused(false);
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') onClick();
  };

  // To avoid animation on the first load we swap the animation configs after animations are enabled.
  const textAnimationSettings = enabled
    ? TEXT_REVEAL_ANIMATION
    : TEXT_REVEAL_ANIMATION_ON_LOAD;

  return (
    <NavToggleWrapper
      onClick={handleClick}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <SkipInitialAnimatePresence>
        {!hidden && (
          <LabelWrapper {...FADE_IN_OUT_ANIMATION}>
            <AnimatePresence exitBeforeEnter initial={false}>
              {!opened && !hovering && !focused && (
                <StyledTextReveal
                  key={closedLabel}
                  content={closedLabel}
                  reveal="character"
                  $theme="dark"
                  {...textAnimationSettings}
                />
              )}

              {!opened && (hovering || focused) && (
                <StyledTextReveal
                  key={hoveringLabel}
                  content={hoveringLabel}
                  reveal="character"
                  $theme="dark"
                  {...textAnimationSettings}
                />
              )}

              {opened && (
                <StyledTextReveal
                  key={openedLabel}
                  content={openedLabel}
                  reveal="character"
                  $theme="light"
                  {...textAnimationSettings}
                />
              )}
            </AnimatePresence>
          </LabelWrapper>
        )}
      </SkipInitialAnimatePresence>
      <SkipInitialAnimatePresence>
        {!hidden && (
          <Toggle
            $hovering={hovering}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            onKeyPress={handleKeyPress}
            tabIndex={0}
            {...SCALE_IN_OUT_ANIMATION}
          />
        )}
      </SkipInitialAnimatePresence>
    </NavToggleWrapper>
  );
};

export default NavToggle;
