import {motion} from 'framer-motion';

import {theme} from '@/content/cms/types';
import {COLOR_BLACK, COLOR_ORANGE, COLOR_WHITE} from '@/theme/colors';
import {
  TRANSITION_SPEED_RAPID,
  TRANSITION_SPEED_SLOTH,
  EASE_CUBIC,
  TRANSITION_SPEED_FAST,
  TRANSITION_SPEED_REGULAR,
  EASE_ON,
  EASE_OUT,
  TRANSITION_SPEED_SLOW,
} from '@/theme/transitions';

import {StyledSmallLogo} from './styles';

const MOUTH_ANIMATION = {
  initial: {scaleX: 0},
  animate: {
    scaleX: 1,
    originX: 0,
    transition: {
      delay: TRANSITION_SPEED_SLOW,
      duration: TRANSITION_SPEED_REGULAR,
      ease: EASE_ON,
    },
  },
  exit: {
    scaleX: 0,
    transition: {duration: TRANSITION_SPEED_REGULAR, ease: EASE_OUT},
  },
};

const EYES_ANIMATION = {
  initial: {scaleY: 0},
  animate: {
    scaleY: 1,
    transition: {
      delay: TRANSITION_SPEED_SLOTH,
      duration: TRANSITION_SPEED_FAST,
      ease: EASE_CUBIC,
      repeat: 1,
      repeatDelay: TRANSITION_SPEED_RAPID,
    },
  },
  exit: {scaleY: 0},
  transition: {duration: TRANSITION_SPEED_FAST, ease: EASE_CUBIC},
};

const BLINK_ANIMATION = {
  animate: {
    scaleY: -1,
    transition: {
      duration: TRANSITION_SPEED_RAPID,
      repeat: Infinity,
      delay: TRANSITION_SPEED_SLOTH,
      repeatDelay: 5,
    },
  },
  transition: {duration: TRANSITION_SPEED_SLOTH, ease: EASE_CUBIC},
};

interface Props {
  className?: string;
  theme?: theme;
}

const SmallLogo = ({className, theme = 'dark'}: Props) => {
  const currentTheme = {fill: theme === 'dark' ? COLOR_BLACK : COLOR_WHITE};
  return (
    <StyledSmallLogo
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 40"
      {...currentTheme}
    >
      <motion.path
        {...MOUTH_ANIMATION}
        d="M49.4148 29.4814H0v10.5187h49.4148V29.4814Z"
      />
      <motion.g {...EYES_ANIMATION}>
        <path d="M15.7778.8983H.1428v15.783h15.635V.8983Z" />
        <motion.path
          {...BLINK_ANIMATION}
          fill={COLOR_ORANGE}
          d="M41.2951 17.5748c4.8076 0 8.7049-3.9343 8.7049-8.7874C50 3.9343 46.1027 0 41.2951 0c-4.8077 0-8.705 3.9343-8.705 8.7874 0 4.8531 3.8973 8.7874 8.705 8.7874Z"
        />
      </motion.g>
    </StyledSmallLogo>
  );
};

export default SmallLogo;
