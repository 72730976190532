import {useEffect, useRef, useState} from 'react';

import {useRouter} from 'next/router';
import {browserName} from 'react-device-detect';
import {LocomotiveScrollProvider} from 'react-locomotive-scroll';

import {BREAKPOINT_MOBILE} from '@/theme/mediaQueries';
import {PAGE_TRANSITION_DELAY} from '@/theme/transitions';

import {ScrollWrapper} from './styles';

export interface ScrollPosition {
  x: number;
  y: number;
}

export interface ScrollProperties {
  scroll: ScrollPosition;
  limit: ScrollPosition;
}

interface Props {
  routing: boolean;
}

/**
 * **Note**: Scroll instance needs updating if using components that manipulate
 * height such as accordions. useLocomotiveScroll hook returns scroll instance
 * with an `update` method to do so.
 */
const LocomotiveScroll: React.FC<Props> = ({routing, children}) => {
  const {asPath} = useRouter();
  const scrollWrapperRef = useRef<HTMLDivElement>(null);
  const [active, setActive] = useState<boolean>(true);

  /**
   * Fix for scroll up issue, whenever the page is done navigating, reset
   * locomotive so that it's behaving as the only page. Then the layout
   * fix works.
   */
  useEffect(() => {
    !active && setActive(true);
  }, [active]);

  const disableWithDelay = () => {
    setTimeout(() => active && setActive(false), PAGE_TRANSITION_DELAY);
  };

  /**
   * Update 12/10/2022
   *
   * Disable smooth scrolling on Firefox.
   */
  return active ? (
    <LocomotiveScrollProvider
      options={{
        smooth: browserName !== 'Firefox',
        multiplier: 1,
        getDirection: true,
        smartphone: {smooth: false},
        tablet: {smooth: false, breakpoint: BREAKPOINT_MOBILE},
      }}
      watch={[asPath, routing, active, scrollWrapperRef]}
      location={asPath}
      onLocationChange={disableWithDelay}
      containerRef={scrollWrapperRef}
    >
      <ScrollWrapper data-scroll-container ref={scrollWrapperRef}>
        {children}
      </ScrollWrapper>
    </LocomotiveScrollProvider>
  ) : null;
};

export default LocomotiveScroll;
