import {createGlobalStyle} from 'styled-components';

import {CookieBannerCSS} from '@/config/cookies';
import {COLOR_HIGHLIGHT, COLOR_LATTE} from '@/theme/colors';

import {
  GRID_COLUMNS_MOBILE,
  GRID_COLUMNS_TABLET,
  GRID_COLUMNS_DESKTOP,
  GRID_OVERLAY,
} from './grid';
import {LocomotiveScrollCSS} from './locomotiveScroll';
import {
  QUERY_GREATER_THAN_MOBILE,
  QUERY_GREATER_THAN_TABLET,
} from './mediaQueries';
import {TYPE_FONT_SANS, TYPE_WEIGHT_SEMI_BOLD} from './type';

export default createGlobalStyle`

  html {
    ${TYPE_FONT_SANS};
    ${TYPE_WEIGHT_SEMI_BOLD};
    background: ${COLOR_LATTE};

    /* Set default app box-sizing */
    box-sizing: border-box;

    /* Smooth fonts */
    -webkit-font-smoothing: antialiased;

    /* Responsive grid */
    font-size: ${100 / GRID_COLUMNS_MOBILE}vw;

    @media ${QUERY_GREATER_THAN_MOBILE} {
      font-size: ${100 / GRID_COLUMNS_TABLET}vw;
    }

    @media ${QUERY_GREATER_THAN_TABLET} {
      font-size: ${100 / GRID_COLUMNS_DESKTOP}vw;
    }

    ${GRID_OVERLAY};
  }

  body {
    margin: 0;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
    -webkit-tap-highlight-color: transparent;
  }

  *::selection {
    background: ${COLOR_HIGHLIGHT};
  }

  ${CookieBannerCSS}
  ${LocomotiveScrollCSS}
`;
