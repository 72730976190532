import {MouseEvent, ButtonHTMLAttributes} from 'react';

import {EventDataProps, trackEvent} from '@phntms/react-gtm';

import {Link as ContentfulLink} from '@/content/cms/types';

import {StyledButton, StyledLink} from './styles';

export type IconPosition = 'right' | 'left';

interface Props extends ButtonHTMLAttributes<HTMLElement> {
  className?: string;
  href?: string;
  link?: ContentfulLink;
  external?: boolean;
  tracking: EventDataProps;
  active?: boolean;
  secondary?: boolean;
  round?: boolean;
  iconPosition?: IconPosition;
  animateArrow?: boolean;
  centerWithinContainer?: boolean;
  disable?: boolean;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  small?: boolean;
}

const Button: React.FC<Props> = ({
  className,
  href,
  link,
  external,
  tracking,
  active,
  secondary,
  round,
  iconPosition,
  animateArrow,
  centerWithinContainer,
  disable,
  children,
  onClick,
  small,
  ...rest
}) => {
  // Emit click events + track events
  const handleClick =
    (onClick || tracking) && !disable
      ? (event: MouseEvent<HTMLButtonElement>) => {
          trackEvent({data: tracking});
          if (onClick) onClick(event);
        }
      : undefined;

  const cursorInteractions = {
    onMouseOver: (event: MouseEvent<HTMLElement>) => {
      if (rest.onMouseOver) rest.onMouseOver(event);
    },
    onMouseOut: (event: MouseEvent<HTMLElement>) => {
      if (rest.onMouseOut) rest.onMouseOut(event);
    },
  };

  return (href || link) && !disable ? (
    <StyledLink
      className={className}
      href={href}
      link={link}
      external={external}
      $active={active}
      $secondary={secondary}
      $small={small}
      $round={round}
      tracking={tracking}
      $iconPosition={iconPosition}
      $animateArrow={animateArrow}
      $centerWithinContainer={centerWithinContainer}
      onClick={onClick}
      {...rest}
      {...cursorInteractions}
    >
      {children}
    </StyledLink>
  ) : (
    <StyledButton
      className={className}
      $active={active && !disable}
      $secondary={secondary}
      $small={small}
      $round={round}
      $iconPosition={iconPosition}
      $animateArrow={animateArrow}
      $centerWithinContainer={centerWithinContainer}
      disabled={disable}
      onClick={handleClick}
      {...rest}
      {...cursorInteractions}
    >
      {children}
    </StyledButton>
  );
};

export default Button;
