import {css} from 'styled-components';

import {COLOR_RED_30, COLOR_RED_50} from './colors';
import {
  QUERY_GREATER_THAN_MOBILE,
  QUERY_GREATER_THAN_TABLET,
} from './mediaQueries';
import {PREVENT_CLICKS} from './pointerEvents';
import {Z_INDEX_DEBUG} from './zIndex';

// GRID UTIL

export const GRID_COLUMNS_MOBILE = 16;
export const GRID_COLUMNS_TABLET = 20;
export const GRID_COLUMNS_DESKTOP = 24;

const TARGET_VIEWPORT_WIDTH_MOBILE = 375;
const TARGET_VIEWPORT_WIDTH_TABLET = 1024;
const TARGET_VIEWPORT_WIDTH_DESKTOP = 1440;

const COLUMN_WIDTH_MOBILE = TARGET_VIEWPORT_WIDTH_MOBILE / GRID_COLUMNS_MOBILE;
const COLUMN_WIDTH_TABLET = TARGET_VIEWPORT_WIDTH_TABLET / GRID_COLUMNS_TABLET;
const COLUMN_WIDTH_DESKTOP =
  TARGET_VIEWPORT_WIDTH_DESKTOP / GRID_COLUMNS_DESKTOP;

/**
 * Used to convert PX values to REM.
 *
 * @param pxAsMin REM values scale based on current screen size,
 * which means that visually a value can drop below it's base PX when below
 * the `targetScreenSize`. By default this is disabled (`true`), to allow
 * values to drop below set this to `false`. Or, to use custom min thresholds
 * add a custom PX value instead.
 *
 * Examples (Where X is a rem value):
 * - pxToRem(20, false) will use `X` without a min.
 * - pxToRem(20) will use `max(20, X)`.
 * - pxToRem(20, 15) will use `max(15, X)`.
 *
 * **Note**: CSS `max` is used under the hood to achieve this, which means
 * `-${pxToRem(x)}` wouldn't work if this is enabled, to use negative values,
 * define them inside as the PX value.
 *
 * @param targetScreenSize PX to REM conversion works by targeting a given
 * screen width for scaling. This means if the current screen size is larger
 * than the target, the value will visually appear larger and vice versa if
 * below the target.
 *
 * Examples:
 * - Mobile: `pxToRem(5, true, 'mobile')` = Visually 5px at **375px**.
 * - Tablet: `pxToRem(5, false, 'tablet')` = Visually 5px at **1024px**.
 * - Desktop: `pxToRem(5)` = Visually 5px at **1440px**.
 */
export const pxToRem = (
  px: number,
  pxAsMin: boolean | number = true,
  targetScreenSize: 'mobile' | 'tablet' | 'desktop' = 'desktop',
) => {
  const columnWidth = {
    mobile: COLUMN_WIDTH_MOBILE,
    tablet: COLUMN_WIDTH_TABLET,
    desktop: COLUMN_WIDTH_DESKTOP,
  }[targetScreenSize];
  const rem = `${px / columnWidth}rem`;
  const minPx = Number.isInteger(pxAsMin) ? pxAsMin : px;
  return pxAsMin ? `max(${minPx}px,${rem})` : rem;
};

/** 1 gutter (on mobile, tablet or desktop) = 1 rem. */
export const gridGutters = (columns: number) => `${columns}rem`;

// GRID OVERLAY

export const GRID_OVERLAY_VISIBLE_CLASS = 'has-grid-overlay';

export const GRID_OVERLAY = css`
  &.${GRID_OVERLAY_VISIBLE_CLASS} {
    &:before {
      content: 'MOBILE - ${GRID_COLUMNS_MOBILE} COLUMNS';
      position: fixed;
      top: 4px;
      left: 4px;
      font-size: 12px;
      line-height: 12px;
      font-weight: 600;
      color: ${COLOR_RED_50};
      writing-mode: vertical-rl;

      @media ${QUERY_GREATER_THAN_MOBILE} {
        content: 'TABLET - ${GRID_COLUMNS_TABLET} COLUMNS';
      }

      @media ${QUERY_GREATER_THAN_TABLET} {
        content: 'DESKTOP - ${GRID_COLUMNS_DESKTOP} COLUMNS';
      }
    }

    &:after {
      content: '';
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background-image: linear-gradient(
        to right,
        ${COLOR_RED_30} 1px,
        transparent 0
      );
      background-size: calc(100% / ${GRID_COLUMNS_MOBILE});

      @media ${QUERY_GREATER_THAN_MOBILE} {
        background-size: calc(100% / ${GRID_COLUMNS_TABLET});
      }

      @media ${QUERY_GREATER_THAN_TABLET} {
        background-size: calc(100% / ${GRID_COLUMNS_DESKTOP});
      }
    }

    &:before,
    &:after {
      z-index: ${Z_INDEX_DEBUG};
      ${PREVENT_CLICKS}
    }
  }
`;
