import styled from 'styled-components';

import SmallLogo from '@/components/Logo/SmallLogo';

export const StyledSmallLogo = styled(SmallLogo)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
