import {trackEvent} from '@phntms/react-gtm';

import {eventToggleHamburger} from '@/config/tracking';
import {useAnimationSettings} from '@/contexts/animationSettings';
import {useNav} from '@/contexts/nav';

import Nav from './Nav';
import NavToggle from './NavToggle';
import {StyledHeader, StyledLogo} from './styles';

interface Props {
  routing: boolean;
}

const Header = ({routing}: Props) => {
  const {
    header,
    label,
    opened,
    toggle,
    atTopOfPage,
    navShowing,
    subNavShowing,
  } = useNav();

  const {enabled} = useAnimationSettings();

  const handleClick = () => {
    trackEvent({data: eventToggleHamburger(!opened ? 'Opened' : 'Closed')});
    toggle();
  };

  // If header content hasn't been fetched yet, serve static version.
  if (!header)
    return (
      <StyledHeader>
        <StyledLogo
          theme={subNavShowing ? 'light' : 'dark'}
          animate={!routing && enabled}
        />
        <NavToggle
          closedLabel=""
          openedLabel=""
          hoveringLabel=""
          onClick={handleClick}
        />
      </StyledHeader>
    );

  return (
    <StyledHeader>
      <StyledLogo
        smallLogo={!routing && !atTopOfPage}
        theme={subNavShowing ? 'light' : 'dark'}
        animate={!routing}
        hidden={
          subNavShowing ||
          (!navShowing && !subNavShowing) ||
          (opened && !atTopOfPage)
        }
      />
      <NavToggle
        closedLabel={label as string}
        hoveringLabel={header.hoveringLabel}
        openedLabel={header.openedLabel}
        hidden={subNavShowing || (!navShowing && !subNavShowing && !opened)}
        onClick={handleClick}
      />
      <Nav onClick={handleClick} />
    </StyledHeader>
  );
};

export default Header;
