import {AnimatePresence} from 'framer-motion';
import type {AppProps} from 'next/app';

import Cursor from '@/components/Cursor';
import Header from '@/components/Header';
import JourneySubNav from '@/components/Header/JourneySubNav';
import Loader from '@/components/Loader';
import LocomotiveScroll from '@/components/LocomotiveScroll';
import {CookieBannerEmbed} from '@/config/cookies';
import {TrackingEmbed} from '@/config/tracking';
import {AnimationSettingsProvider} from '@/contexts/animationSettings';
import {CursorProvider} from '@/contexts/cursor';
import {NavProvider} from '@/contexts/nav';
import {UserBehaviorProvider} from '@/contexts/userBehavior';
import useReloadOnContextChange from '@/hooks/useReloadOnContextChange';
import useRouting from '@/hooks/useRouting';
import GlobalStyles from '@/theme/global';
import {PAGE_TRANSITION_DELAY} from '@/theme/transitions';

const GTM_CONTAINER_ID = process.env.NEXT_PUBLIC_GTM_CONTAINER_ID || '';

/**
 * Fix flickering caused by using @font-face with createGlobalStyle.
 * Reference: https://github.com/styled-components/styled-components/issues/2205#issuecomment-438844490
 */
import '@/theme/fontFaceStyles.css';

const App = ({Component, pageProps, router}: AppProps) => {
  /**
   * Page transition takes 400 to finish, so wait slightly longer to ensure
   * page has been removed fully.
   */
  const routing = useRouting(PAGE_TRANSITION_DELAY);

  useReloadOnContextChange();

  return (
    <>
      <GlobalStyles />
      <UserBehaviorProvider>
        <AnimationSettingsProvider>
          <CursorProvider>
            <NavProvider>
              <Header routing={routing} />
              <LocomotiveScroll routing={routing}>
                <JourneySubNav />
                <AnimatePresence exitBeforeEnter initial={false}>
                  {!routing ? (
                    <Component {...pageProps} key={router.asPath} />
                  ) : (
                    <Loader />
                  )}
                </AnimatePresence>
              </LocomotiveScroll>
              <Cursor />
            </NavProvider>
          </CursorProvider>
        </AnimationSettingsProvider>
      </UserBehaviorProvider>
      <TrackingEmbed id={GTM_CONTAINER_ID} />
      <CookieBannerEmbed />
    </>
  );
};

export default App;
