import Script from 'next/script';
import {css, keyframes} from 'styled-components';

import {BORDER_RADIUS_SMALL, onFocusOutline} from '@/theme/borders';
import {COLOR_BLACK, COLOR_WHITE, COLOR_BLACK_20} from '@/theme/colors';
import {SPACING_S, SPACING_M, SPACING_XS, SPACING_XXS} from '@/theme/spacings';
import {
  TRANSITION_SPEED_FAST,
  TRANSITION_EASE_CUBIC,
} from '@/theme/transitions';
import {
  TYPE_FONT_SANS,
  TYPE_SIZE_SANS_SMALL,
  TYPE_WEIGHT_REGULAR,
} from '@/theme/type';

export const CookieBannerEmbed = () => (
  <Script
    strategy="lazyOnload"
    data-autoload-cookie-consent-bar="true"
    data-autoload-cookie-consent-bar-intl-code=""
    src="https://www.gstatic.com/brandstudio/kato/cookie_choice_component/cookie_consent_bar.v3.js"
  />
);

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

export const CookieBannerCSS = css`
  /* Needs wrapper (body) to increase css specificity */
  body #cookieBar {
    background: transparent;
    right: ${SPACING_S};
    bottom: ${SPACING_S};
    left: ${SPACING_S};
    display: flex;
    justify-content: center;
    animation: ${fadeIn} ${TRANSITION_SPEED_FAST}s ${TRANSITION_EASE_CUBIC}
      forwards;

    /* Remove default 'width: 100%' overflowing on mobile */
    width: auto;

    > .cookieBarInner {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      background-color: ${COLOR_WHITE};
      color: ${COLOR_BLACK};
      border-radius: ${BORDER_RADIUS_SMALL};
      padding: ${SPACING_XS} ${SPACING_M};

      > .cookieBarText,
      > .cookieBarButtons {
        ${TYPE_FONT_SANS}
        ${TYPE_SIZE_SANS_SMALL}
        ${TYPE_WEIGHT_REGULAR}
      }

      > .cookieBarText,
      > .cookieBarButtons > .cookieBarButton {
        margin: ${SPACING_XXS} ${SPACING_S};
      }

      > .cookieBarText {
        text-align: center;
      }

      > .cookieBarButtons {
        display: flex;

        > .cookieBarButton {
          background: transparent;
          color: ${COLOR_BLACK};
          border: 1px solid ${COLOR_BLACK_20};
          padding: ${SPACING_XS} ${SPACING_S};
          border-radius: ${BORDER_RADIUS_SMALL};
          transition: border-color ${TRANSITION_SPEED_FAST}s
            ${TRANSITION_EASE_CUBIC};

          /* Remove default line-height */
          line-height: inherit;

          &:first-child {
            margin-right: 0;
          }

          ${onFocusOutline()}

          &:hover {
            border-color: ${COLOR_BLACK};
          }
        }
      }
    }
  }
`;
