import useKey from '@phntms/use-key';
import {AnimatePresence} from 'framer-motion';

import {CursorType} from '@/components/Cursor';
import {
  eventClickHamburgerLink,
  eventClickHamburgerSubLink,
} from '@/config/tracking';
import {useNav} from '@/contexts/nav';
import useClientMediaQuery from '@/hooks/useClientMediaQuery';
import {gridGutters} from '@/theme/grid';
import {QUERY_GREATER_THAN_MOBILE} from '@/theme/mediaQueries';
import {SPACING_L, SPACING_M} from '@/theme/spacings';
import {
  EASE_CUBIC,
  EASE_ON,
  EASE_OUT,
  TRANSITION_SPEED_FAST,
  TRANSITION_SPEED_RAPID,
  TRANSITION_SPEED_SLOTH,
  TRANSITION_SPEED_SLOW,
} from '@/theme/transitions';

import {TOGGLE_HALF_SIZE} from '../NavToggle/styles';
import {
  NavWrapper,
  StyledLogo,
  StyledMedia,
  ContentWrapper,
  ContentTop,
  ContentBottom,
  ContentTopOuterLinkWrapper,
  ContentTopInnerLinkWrapper,
  StyledLink,
  ContentBottomLinksWrapper,
  ContentBottomLinkWrapper,
  StyledSocials,
} from './styles';

const DELAY_BETWEEN_ANIMATE_REVEALS = 0.06;
const DELAY_BETWEEN_EXIT_REVEALS = 0.02;

const contentTopLinkAnimation = (animateIndex: number, exitIndex: number) => ({
  initial: {y: '100%'},
  animate: {
    y: '0',
    transition: {
      duration: TRANSITION_SPEED_SLOW,
      delay:
        TRANSITION_SPEED_RAPID + DELAY_BETWEEN_ANIMATE_REVEALS * animateIndex,
      ease: EASE_ON,
    },
  },
  exit: {
    y: '-100%',
    transition: {
      duration: TRANSITION_SPEED_SLOW,
      delay: DELAY_BETWEEN_EXIT_REVEALS * exitIndex,
      ease: EASE_OUT,
    },
  },
});

const contentBottomLinkAnimation = (
  animateIndex: number,
  exitIndex: number,
) => ({
  initial: {y: '100%', opacity: 0},
  animate: {
    y: '0',
    opacity: 1,
    transition: {
      duration: TRANSITION_SPEED_SLOW,
      delay:
        TRANSITION_SPEED_FAST + DELAY_BETWEEN_ANIMATE_REVEALS * animateIndex,
      ease: EASE_ON,
    },
  },
  exit: {
    y: '-100%',
    opacity: 0,
    transition: {
      duration: TRANSITION_SPEED_SLOW,
      delay: DELAY_BETWEEN_EXIT_REVEALS * exitIndex,
      ease: EASE_OUT,
    },
  },
});

const SCALE_IN_OUT_ANIMATION = {
  initial: {scale: 0},
  animate: {
    scale: 1,
    transition: {
      duration: TRANSITION_SPEED_SLOW,
      delay: TRANSITION_SPEED_FAST,
      ease: EASE_ON,
    },
  },
  exit: {
    scale: 0,
    transition: {
      duration: TRANSITION_SPEED_SLOW,
      ease: EASE_OUT,
    },
  },
};

const wrapperClipPath = (isMobile = true, showing?: boolean) => {
  const radius = showing ? 150 : 0;

  const topSpacing = isMobile ? SPACING_M : SPACING_L;
  const rightSpacing = gridGutters(1);
  const toggleSize = TOGGLE_HALF_SIZE;

  return `circle(${radius}% at calc(100% - calc(${rightSpacing} + ${toggleSize})) calc(${topSpacing} + ${toggleSize}))`;
};

interface Props {
  onClick: () => void;
}

const Nav = ({onClick}: Props) => {
  const {header, opened, atTopOfPage} = useNav();

  useKey('Escape', (pressed: boolean) => {
    if (pressed && opened) onClick();
  });

  const clientIsTablet = useClientMediaQuery(QUERY_GREATER_THAN_MOBILE);
  const wrapperVariants = clientIsTablet
    ? {
        initial: {clipPath: wrapperClipPath(false)},
        animate: {clipPath: wrapperClipPath(false, true)},
        exit: {clipPath: wrapperClipPath(false)},
      }
    : {
        initial: {clipPath: wrapperClipPath()},
        animate: {clipPath: wrapperClipPath(true, true)},
        exit: {clipPath: wrapperClipPath()},
      };

  // If header content hasn't been fetched yet, ignore rest
  if (!header) return null;

  return (
    <AnimatePresence>
      {opened && (
        <NavWrapper
          key={clientIsTablet ? 'tablet' : 'mobile'}
          {...wrapperVariants}
          transition={{duration: TRANSITION_SPEED_SLOTH, ease: EASE_CUBIC}}
          onClick={onClick}
        >
          <StyledLogo theme="light" animate={!atTopOfPage} />
          <StyledMedia
            media={header.media}
            ignoreAspectRatio
            objectFit="cover"
            lazy
          />
          <ContentWrapper>
            <ContentTop>
              {header.linksCollection.items.map((link, index) => {
                let animateIndex: number;
                let exitIndex: number;

                const isEvenIndex = index % 2 === 0;
                const halfIndex = index / 2;

                // If tablet reveal top right to bottom right
                if (clientIsTablet) {
                  animateIndex = isEvenIndex
                    ? Math.ceil(halfIndex) + 1
                    : Math.floor(halfIndex);
                  exitIndex = Math.abs(
                    animateIndex -
                      Math.ceil(header.linksCollection.items.length / 2),
                  );
                }

                // Else, on mobile reveal top to bottom
                else {
                  animateIndex =
                    (isEvenIndex ? halfIndex + 1 : Math.ceil(halfIndex) + 4) -
                    1;
                  exitIndex =
                    header.linksCollection.items.length - 1 - animateIndex;
                }

                return (
                  <ContentTopOuterLinkWrapper key={index} $index={index}>
                    <ContentTopInnerLinkWrapper
                      {...contentTopLinkAnimation(animateIndex, exitIndex)}
                    >
                      <StyledLink
                        link={link}
                        tracking={eventClickHamburgerLink(link.title)}
                        cursor={CursorType.ExternalLink}
                      >
                        {link.title}
                      </StyledLink>
                    </ContentTopInnerLinkWrapper>
                  </ContentTopOuterLinkWrapper>
                );
              })}
            </ContentTop>
            <ContentBottom>
              <ContentBottomLinksWrapper>
                {header.subLinksCollection.items.map((link, index) => {
                  let animateIndex: number;
                  let exitIndex: number;

                  // If tablet reveal right to left
                  if (clientIsTablet) {
                    animateIndex =
                      header.subLinksCollection.items.length - index - 1;
                    exitIndex = index;
                  }

                  // Else, on mobile reveal top to bottom
                  else {
                    animateIndex = index;
                    exitIndex =
                      header.subLinksCollection.items.length - index - 1;
                  }

                  return (
                    <ContentBottomLinkWrapper
                      key={index}
                      {...contentBottomLinkAnimation(animateIndex, exitIndex)}
                    >
                      <StyledLink
                        link={link}
                        tracking={eventClickHamburgerSubLink(link.title)}
                      >
                        {link.title}
                      </StyledLink>
                    </ContentBottomLinkWrapper>
                  );
                })}
              </ContentBottomLinksWrapper>
              <StyledSocials
                socials={header.socialsCollection.items}
                theme="light"
                {...SCALE_IN_OUT_ANIMATION}
              />
            </ContentBottom>
          </ContentWrapper>
        </NavWrapper>
      )}
    </AnimatePresence>
  );
};

export default Nav;
