import {css} from 'styled-components';

import {COLOR_DARK} from './colors';
import {pxToRem} from './grid';
import {
  QUERY_GREATER_THAN_MOBILE,
  QUERY_GREATER_THAN_TABLET,
} from './mediaQueries';

export const TYPE_FONT_SANS = css`
  font-family: 'Calibre', sans-serif;
`;

export const TYPE_FONT_SERIF = css`
  font-family: 'Source Serif Pro', serif;
  color: ${COLOR_DARK};
`;

export const TYPE_WEIGHT_REGULAR = css`
  font-weight: 400;
`;

export const TYPE_WEIGHT_SEMI_BOLD = css`
  font-weight: 500;
`;

export const TYPE_WEIGHT_BOLD = css`
  font-weight: 600;
`;

// SANS TYPE SIZES

export const TYPE_SIZE_HEADER_1_BASELINE_TABLET = pxToRem(26, false, 'tablet');
export const TYPE_SIZE_HEADER_1_BASELINE_DESKTOP = pxToRem(45, false);

export const TYPE_SIZE_HEADER_1 = css`
  font-size: ${pxToRem(85, false, 'mobile')};
  line-height: ${pxToRem(75, false, 'mobile')};
  letter-spacing: ${pxToRem(-1.3, false, 'mobile')};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    font-size: ${pxToRem(150, false, 'tablet')};
    line-height: ${pxToRem(126, false, 'tablet')};
    letter-spacing: ${pxToRem(-3.5, false, 'tablet')};
  }

  @media ${QUERY_GREATER_THAN_TABLET} {
    font-size: ${pxToRem(250, false)};
    line-height: ${pxToRem(210, false)};
    letter-spacing: ${pxToRem(-7.5, false)};
  }
`;

export const TYPE_SIZE_HEADER_1_AND_A_HALF_BASELINE_TABLET = pxToRem(
  21,
  false,
  'tablet',
);
export const TYPE_SIZE_HEADER_1_AND_A_HALF_BASELINE_DESKTOP = pxToRem(
  34,
  false,
);

export const TYPE_SIZE_HEADER_1_AND_A_HALF = css`
  font-size: ${pxToRem(70, false, 'mobile')};
  line-height: ${pxToRem(58, false, 'mobile')};
  letter-spacing: ${pxToRem(-1.2, false, 'mobile')};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    font-size: ${pxToRem(125, false, 'tablet')};
    line-height: ${pxToRem(105, false, 'tablet')};
    letter-spacing: ${pxToRem(-2.5, false, 'tablet')};
  }

  @media ${QUERY_GREATER_THAN_TABLET} {
    font-size: ${pxToRem(200, false)};
    line-height: ${pxToRem(168, false)};
    letter-spacing: ${pxToRem(-5, false)};
  }
`;

export const TYPE_SIZE_HEADER_2_BASELINE_TABLET = pxToRem(18, false, 'tablet');
export const TYPE_SIZE_HEADER_2_BASELINE_DESKTOP = pxToRem(25, false);

export const TYPE_SIZE_HEADER_2 = css`
  font-size: ${pxToRem(55, false, 'mobile')};
  line-height: ${pxToRem(48, false, 'mobile')};
  letter-spacing: ${pxToRem(-1.1, false, 'mobile')};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    font-size: ${pxToRem(100, false, 'tablet')};
    line-height: ${pxToRem(86, false, 'tablet')};
    letter-spacing: ${pxToRem(-1.5, false, 'tablet')};
  }

  @media ${QUERY_GREATER_THAN_TABLET} {
    font-size: ${pxToRem(140, false)};
    line-height: ${pxToRem(118, false)};
    letter-spacing: ${pxToRem(-2.9, false)};
  }
`;

export const TYPE_SIZE_HEADER_2_AND_A_HALF_BASELINE_TABLET = pxToRem(
  16,
  false,
  'tablet',
);
export const TYPE_SIZE_HEADER_2_AND_A_HALF_BASELINE_DESKTOP = pxToRem(
  22,
  false,
);

export const TYPE_SIZE_HEADER_2_AND_A_HALF = css`
  font-size: ${pxToRem(49, false, 'mobile')};
  line-height: ${pxToRem(43, false, 'mobile')};
  letter-spacing: ${pxToRem(-0.8, false, 'mobile')};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    font-size: ${pxToRem(83, false, 'tablet')};
    line-height: ${pxToRem(72, false, 'tablet')};
    letter-spacing: ${pxToRem(-1, false, 'tablet')};
  }

  @media ${QUERY_GREATER_THAN_TABLET} {
    font-size: ${pxToRem(116, false)};
    line-height: ${pxToRem(100, false)};
    letter-spacing: ${pxToRem(-2, false)};
  }
`;

export const TYPE_SIZE_HEADER_3 = css`
  font-size: ${pxToRem(45, false, 'mobile')};
  line-height: ${pxToRem(40, false, 'mobile')};
  letter-spacing: ${pxToRem(-0.9, false, 'mobile')};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    font-size: ${pxToRem(65, false, 'tablet')};
    line-height: ${pxToRem(56, false, 'tablet')};
    letter-spacing: ${pxToRem(-1, false, 'tablet')};
  }

  @media ${QUERY_GREATER_THAN_TABLET} {
    font-size: ${pxToRem(80, false)};
    line-height: ${pxToRem(70, false)};
    letter-spacing: ${pxToRem(-1.6, false)};
  }
`;

export const TYPE_SIZE_HEADER_4 = css`
  font-size: ${pxToRem(30, false, 'mobile')};
  line-height: ${pxToRem(29, false, 'mobile')};
  letter-spacing: ${pxToRem(-0.6, false, 'mobile')};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    font-size: ${pxToRem(40, false, 'tablet')};
    line-height: ${pxToRem(38, false, 'tablet')};
    letter-spacing: ${pxToRem(-0.5, false, 'tablet')};
  }

  @media ${QUERY_GREATER_THAN_TABLET} {
    font-size: ${pxToRem(50, false)};
    line-height: ${pxToRem(48, false)};
    letter-spacing: ${pxToRem(-1, false)};
  }
`;

export const TYPE_SIZE_HEADER_5 = css`
  font-size: ${pxToRem(30, false, 'mobile')};
  line-height: ${pxToRem(29, false, 'mobile')};
  letter-spacing: ${pxToRem(-0.15, false, 'mobile')};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    font-size: ${pxToRem(30, false, 'tablet')};
    line-height: ${pxToRem(29, false, 'tablet')};
    letter-spacing: ${pxToRem(-0.15, false, 'tablet')};
  }

  @media ${QUERY_GREATER_THAN_TABLET} {
    font-size: ${pxToRem(35, false)};
    line-height: ${pxToRem(34, false)};
    letter-spacing: ${pxToRem(-0.35, false)};
  }
`;

export const TYPE_SIZE_HEADER_6 = css`
  font-size: ${pxToRem(25, false, 'mobile')};
  line-height: ${pxToRem(24, false, 'mobile')};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    font-size: ${pxToRem(25, false, 'tablet')};
    line-height: ${pxToRem(24, false, 'tablet')};
  }

  @media ${QUERY_GREATER_THAN_TABLET} {
    font-size: ${pxToRem(25, false)};
    line-height: ${pxToRem(24, false)};
  }
`;

export const TYPE_SIZE_SANS_REGULAR = css`
  font-size: ${pxToRem(17, 14, 'mobile')};
  line-height: ${pxToRem(16, 14, 'mobile')};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    font-size: ${pxToRem(17, 14, 'tablet')};
    line-height: ${pxToRem(16, 14, 'tablet')};
  }

  @media ${QUERY_GREATER_THAN_TABLET} {
    font-size: ${pxToRem(17, 14)};
    line-height: ${pxToRem(16, 14)};
  }
`;

export const TYPE_SIZE_SANS_SMALL = css`
  font-size: ${pxToRem(14, 12, 'mobile')};
  line-height: ${pxToRem(14, 12, 'mobile')};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    font-size: ${pxToRem(14, 12, 'tablet')};
    line-height: ${pxToRem(14, 12, 'tablet')};
  }

  @media ${QUERY_GREATER_THAN_TABLET} {
    font-size: ${pxToRem(14, 12)};
    line-height: ${pxToRem(14, 12)};
  }
`;

// SERIF TYPE SIZES

export const TYPE_SIZE_LARGE_BASELINE_TABLET = pxToRem(10, false, 'tablet');
export const TYPE_SIZE_LARGE_BASELINE_DESKTOP = pxToRem(10, false);

export const TYPE_SIZE_LARGE_LINE_HEIGHT_MOBILE = pxToRem(30, false, 'mobile');
export const TYPE_SIZE_LARGE_LINE_HEIGHT_TABLET = pxToRem(30, false, 'tablet');
export const TYPE_SIZE_LARGE_LINE_HEIGHT_DESKTOP = pxToRem(
  30,
  false,
  'desktop',
);

export const TYPE_SIZE_LARGE = css`
  font-size: ${pxToRem(17, 14, 'mobile')};
  line-height: ${TYPE_SIZE_LARGE_LINE_HEIGHT_MOBILE};
  letter-spacing: -${pxToRem(0.4, false, 'mobile')};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    font-size: ${pxToRem(17, 14, 'tablet')};
    line-height: ${TYPE_SIZE_LARGE_LINE_HEIGHT_DESKTOP};
    letter-spacing: -${pxToRem(0.4, false, 'tablet')};
  }

  @media ${QUERY_GREATER_THAN_TABLET} {
    font-size: ${pxToRem(17, 14)};
    line-height: ${TYPE_SIZE_LARGE_LINE_HEIGHT_DESKTOP};
    letter-spacing: -${pxToRem(0.4, false)};
  }
`;

export const TYPE_SIZE_REGULAR = css`
  font-size: ${pxToRem(15, 12, 'mobile')};
  line-height: ${pxToRem(25, false, 'mobile')};
  letter-spacing: ${pxToRem(-0.34, false, 'mobile')};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    font-size: ${pxToRem(15, 12, 'tablet')};
    line-height: ${pxToRem(25, false, 'tablet')};
    letter-spacing: ${pxToRem(-0.34, false, 'tablet')};
  }

  @media ${QUERY_GREATER_THAN_TABLET} {
    font-size: ${pxToRem(15, 12)};
    line-height: ${pxToRem(25, false)};
    letter-spacing: ${pxToRem(-0.34, false)};
  }
`;
