export interface SVGRProps {
  className?: string;
  width?: number | string;
  height?: number | string;
}

export enum Direction {
  Up = '0deg',
  UpperRight = '45deg',
  Right = '90deg',
  LowerRight = '135deg',
  Down = '180deg',
  LowerLeft = '225deg',
  Left = '270deg',
  UpperLeft = '315deg',
}
