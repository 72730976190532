import {css} from 'styled-components';

import {COLOR_BLACK} from './colors';
import {
  TRANSITION_EASE_ON,
  TRANSITION_SPEED_FAST,
  TRANSITION_SPEED_SLOW,
} from './transitions';
import {Z_INDEX_CONTENT_OVERLAY} from './zIndex';

/** locomotive-scroll v4.1.1 CSS + Custom styles. */
export const LocomotiveScrollCSS = css`
  html.has-scroll-smooth {
    overflow: hidden;

    /* Fix - https://github.com/locomotivemtl/locomotive-scroll/issues/361 */
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  html.has-scroll-dragging {
    user-select: none;
  }

  .has-scroll-smooth body {
    overflow: hidden;
  }

  .has-scroll-smooth [data-scroll-container] {
    min-height: 100vh;
  }

  [data-scroll-direction='horizontal'] [data-scroll-container] {
    height: 100vh;
    display: inline-block;
    white-space: nowrap;
  }

  [data-scroll-direction='horizontal'] [data-scroll-section] {
    display: inline-block;
    vertical-align: top;
    white-space: nowrap;
    height: 100%;
  }

  .c-scrollbar {
    position: absolute;
    right: 0;
    top: 0;
    width: 11px;
    height: 100%;
    transform-origin: center right;
    transition: ${TRANSITION_SPEED_FAST}s;
    transition-property: transform, opacity;
    opacity: 0;
    z-index: ${Z_INDEX_CONTENT_OVERLAY};
  }

  .c-scrollbar:hover {
    transform: scaleX(1.45);
  }

  .c-scrollbar:hover,
  .has-scroll-scrolling .c-scrollbar,
  .has-scroll-dragging .c-scrollbar {
    opacity: 1;
  }

  [data-scroll-direction='horizontal'] .c-scrollbar {
    width: 100%;
    height: 10px;
    top: auto;
    bottom: 0;
    transform: scaleY(1);
  }

  [data-scroll-direction='horizontal'] .c-scrollbar:hover {
    transform: scaleY(1.2);
  }

  .c-scrollbar_thumb {
    position: absolute;
    top: 0;
    right: 0;
    background-color: ${COLOR_BLACK};
    opacity: 0.5;
    width: 7px;
    border-radius: 10px;
    margin: 2px;
    cursor: grab;
    transition: height ${TRANSITION_SPEED_SLOW}s ${TRANSITION_EASE_ON},
      transform ${TRANSITION_SPEED_FAST}s ${TRANSITION_EASE_ON};
  }

  .has-scroll-dragging .c-scrollbar_thumb {
    cursor: grabbing;
  }

  [data-scroll-direction='horizontal'] .c-scrollbar_thumb {
    right: auto;
    bottom: 0;
  }
`;
