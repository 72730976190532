import {useEffect} from 'react';

import {IS_SAFARI} from '@/utils/environment';

const swapLazyLoadingAttributes = (
  element: HTMLImageElement | HTMLSourceElement,
  onSwapCallback?: () => void,
) => {
  if (element.dataset.type && element instanceof HTMLSourceElement) {
    element.type = element.dataset.type;
  }

  if (element.dataset.src) {
    element.src = element.dataset.src;
  }

  if (element.dataset.srcset) {
    element.srcset = element.dataset.srcset;
  }

  onSwapCallback && onSwapCallback();
};

const assignLoadedAttribute = (element: HTMLElement) =>
  element.setAttribute('data-loaded', 'true');

const preloadSource = (element: HTMLPictureElement) => {
  const source = element.querySelector('source');

  if (source) {
    const image = new Image();
    image.onload = () => assignLoadedAttribute(element);
    image.srcset = source.srcset;
    image.remove();
  }
};

const useLazyLoadingMedia = (
  mediaRef: React.MutableRefObject<
    HTMLPictureElement | HTMLDivElement | null | undefined
  >,
  lazy: boolean,
) => {
  useEffect(() => {
    if (!window.IntersectionObserver || !lazy) return;

    const intersection = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const lazyMedia = entry.target;
            if (!lazyMedia) return;

            const element = lazyMedia.firstChild;
            if (element instanceof HTMLVideoElement) {
              const sourceElement = element.querySelector('source');
              element.oncanplay = () => assignLoadedAttribute(element);
              if (sourceElement)
                swapLazyLoadingAttributes(sourceElement, () => element.load());
            }

            if (element instanceof HTMLPictureElement) {
              const sourceElement = element.querySelector('source');
              const fallbackElement = element.querySelector('img');

              if (IS_SAFARI) {
                sourceElement && element.removeChild(sourceElement);
                if (fallbackElement) {
                  fallbackElement.onload = () => assignLoadedAttribute(element);
                  swapLazyLoadingAttributes(fallbackElement);
                }
              } else {
                if (sourceElement) swapLazyLoadingAttributes(sourceElement);
                if (fallbackElement) swapLazyLoadingAttributes(fallbackElement);
                preloadSource(element);
              }
            }

            observer.unobserve(entry.target);
          }
        });
      },
      {threshold: 0.01},
    );

    if (mediaRef.current) {
      intersection.observe(mediaRef.current);
      return () => intersection.disconnect();
    }
  }, [lazy, mediaRef]);
};

export default useLazyLoadingMedia;
