import styled from 'styled-components';

import {pxToRem} from '@/theme/grid';
import {QUERY_GREATER_THAN_MOBILE} from '@/theme/mediaQueries';
import {
  TRANSITION_EASE_CUBIC,
  TRANSITION_SPEED_FAST,
} from '@/theme/transitions';

export const LOGO_HEIGHT_MOBILE = pxToRem(40);
export const LOGO_HEIGHT_DESKTOP = pxToRem(50);

export const StyledLargeLogo = styled.svg`
  height: ${LOGO_HEIGHT_MOBILE};
  transition: fill ${TRANSITION_SPEED_FAST}s ${TRANSITION_EASE_CUBIC};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    height: ${LOGO_HEIGHT_DESKTOP};
  }
`;
