import {motion} from 'framer-motion';

import {theme} from '@/content/cms/types';
import {COLOR_BLACK, COLOR_WHITE} from '@/theme/colors';
import {
  TRANSITION_SPEED_RAPID,
  TRANSITION_SPEED_SLOTH,
  EASE_ON,
  EASE_OUT,
} from '@/theme/transitions';

import {StyledLargeLogo} from './styles';

const LINE_ONE_ANIMATION = {
  initial: {y: '100%'},
  animate: {
    y: '0',
    transition: {duration: TRANSITION_SPEED_SLOTH, ease: EASE_ON},
  },
  exit: {
    y: '-100%',
    transition: {duration: TRANSITION_SPEED_SLOTH, ease: EASE_OUT},
  },
};

const LINE_TWO_ANIMATION = {
  ...LINE_ONE_ANIMATION,
  animate: {
    y: '0',
    transition: {
      delay: TRANSITION_SPEED_RAPID,
      duration: TRANSITION_SPEED_SLOTH,
      ease: EASE_ON,
    },
  },
};

interface Props {
  className?: string;
  theme?: theme;
}

const LargeLogo = ({className, theme = 'dark'}: Props) => {
  const currentTheme = {fill: theme === 'dark' ? COLOR_BLACK : COLOR_WHITE};
  return (
    <StyledLargeLogo
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 137 50"
      {...currentTheme}
    >
      <defs>
        <clipPath id="top-line-clip">
          <rect x="0" y="0" width="100%" height="65%" />
        </clipPath>
        <clipPath id="bottom-line-clip">
          <rect x="0" y="50%" width="100%" height="50%" />
        </clipPath>
      </defs>
      <g clipPath="url(#top-line-clip)">
        <motion.g {...LINE_ONE_ANIMATION}>
          <path d="M17.3912.1155V5.175H6.2602v3.7097h10.1774v5.2144H6.2602v4.0946h11.131v5.3695H0V.1155h17.3912Z" />
          <path d="m23.9297 23.5627-6.773-17.3833h6.3372l3.4095 9.9641 3.348-9.964h6.1013l-6.8345 17.3832h-5.5885Z" />
          <path d="M52.1088 17.4983c-.6307 3.9996-3.7223 6.4493-8.3573 6.4493-5.4655 0-9.0699-3.6696-9.0699-9.0791 0-5.4094 3.8044-9.074 8.952-9.074 5.4655 0 8.5572 3.5546 8.5572 8.7291v1.4998H40.6599c.1999 2.0848 1.3074 3.2097 3.0763 3.2097 1.2664 0 2.261-.545 2.6148-1.7398l5.7578.005Zm-11.3259-4.4996h5.363c-.0769-1.4298-.9895-2.4997-2.5328-2.4997-1.4458 0-2.4354.8949-2.8302 2.4997Z" />
          <path d="M64.7609 11.9337a4.894 4.894 0 0 0-2.3995-.5399c-1.7022 0-2.5636.7199-3.0148 1.9698v10.1989h-6.2192V6.1793h6.2192V7.724c.7281-1.2649 1.9278-1.9298 3.7121-1.9298a3.9422 3.9422 0 0 1 1.7022.31v5.8294Z" />
          <path d="m69.3421 28.8522 2.3225-5.7144-6.3268-16.9584h6.4448l3.0762 9.5691 2.9994-9.569h6.1833l-8.5623 22.6727h-6.1371Z" />
          <path d="M95.1963 22.1728a6.2166 6.2166 0 0 1-2.018 1.3153 6.3427 6.3427 0 0 1-2.381.4595c-5.1272 0-7.9625-4.0546-7.9625-9.0791 0-5.0245 2.8148-9.074 7.9625-9.074a6.3418 6.3418 0 0 1 2.381.4595 6.216 6.216 0 0 1 2.018 1.3153V0h6.2197v23.5627h-6.2197v-1.3899Zm0-9.619c-.2587-.4893-.6506-.8997-1.1326-1.1863a3.1357 3.1357 0 0 0-1.6001-.4386c-2.138 0-3.4096 1.6998-3.4096 3.9396s1.2716 3.9046 3.4096 3.9046a3.0517 3.0517 0 0 0 1.6077-.4264c.4837-.2877.8738-.7032 1.125-1.1984v-4.5945Z" />
          <path d="M113.12 23.6178v-.8849c-.594.58-2.379 1.1949-4.281 1.1949-3.527 0-6.496-1.9298-6.496-5.5994 0-3.3597 2.969-5.4995 6.855-5.4995 1.467 0 3.205.465 3.922.9649v-1.1449a1.9004 1.9004 0 0 0-.125-.8304 1.9406 1.9406 0 0 0-.47-.7027 2.0065 2.0065 0 0 0-.73-.4455 2.05 2.05 0 0 0-.854-.1062c-1.148 0-1.825.5-2.102 1.2749h-5.86c.513-3.3597 3.589-5.9994 8.157-5.9994 5.071 0 7.963 2.6247 7.963 7.1443v10.6339h-5.979Zm0-5.8694c-.399-.7349-1.466-1.1949-2.614-1.1949-1.231 0-2.456.5-2.456 1.6599s1.225 1.6998 2.456 1.6998c1.148 0 2.215-.4649 2.614-1.1599v-1.0049Z" />
          <path d="m122.311 28.8522 2.322-5.7144-6.327-16.9584h6.46l3.077 9.5691 3.004-9.569H137l-8.547 22.6727h-6.142Z" />
        </motion.g>
      </g>
      <g clipPath="url(#bottom-line-clip)">
        <motion.g {...LINE_TWO_ANIMATION}>
          <path d="M6.3448 41.9608V49.53H.0436V26.0824h10.9361c5.2246 0 8.8341 2.8997 8.8341 8.0742 0 3.5946-1.8663 6.0644-4.8349 7.1442l5.7014 8.2292h-7.0498l-5.0708-7.5692H6.3448Zm0-4.9445h3.9582c2.261 0 3.2865-1.1199 3.2865-2.8597 0-1.7399-1.0255-2.8598-3.2865-2.8598H6.3448v5.7195Z" />
          <path d="M28.7099 31.7618c-1.8509-.005-3.6617.5258-5.2031 1.525-1.5414.9992-2.744 2.422-3.4556 4.0881-.7116 1.6662-.9002 3.5007-.5418 5.2715.3583 1.7707 1.2475 3.3979 2.5548 4.6755 1.3074 1.2776 2.9742 2.1482 4.7893 2.5016 1.8151.3533 3.697.1734 5.4072-.5168 1.7103-.6902 3.172-1.8598 4.2001-3.3606 1.0281-1.5008 1.5764-3.2654 1.5754-5.0703-.0014-2.4133-.9837-4.7277-2.7319-6.4361-1.7481-1.7083-4.1194-2.6713-6.5944-2.6779Zm0 13.2336c-1.6509 0-2.9891-1.4298-2.9891-4.1396 0-2.7097 1.3177-4.1095 2.9891-4.1095 1.6715 0 2.9891 1.4248 2.9891 4.1295 0 2.7048-1.3586 4.1196-2.9891 4.1196Z" />
          <path d="M45.1226 49.5302H38.924V25.9976h6.1986v7.4992c1.1673-1.1366 2.7495-1.775 4.3991-1.7748 5.1272 0 7.9625 4.0546 7.9625 9.0791 0 5.0245-2.8097 9.0741-7.9625 9.0741-1.6437.0108-3.2251-.6128-4.3991-1.7348v1.3898Zm0-6.4143c.2511.4963.6419.9127 1.1268 1.2005a3.0474 3.0474 0 0 0 1.6111.4243c2.138 0 3.4044-1.6598 3.4044-3.8996s-1.2664-3.9396-3.4044-3.9396a3.1757 3.1757 0 0 0-1.5985.4409c-.4822.2852-.8759.6927-1.1394 1.1789v4.5946Z" />
          <path d="M67.815 31.7617c-1.8498 0-3.6581.535-5.1961 1.5372-1.5381 1.0022-2.7367 2.4268-3.4444 4.0933-.7077 1.6666-.8926 3.5004-.5313 5.2695.3613 1.7691 1.2525 3.3939 2.5609 4.669 1.3084 1.2752 2.9752 2.1433 4.7896 2.4946 1.8144.3513 3.695.17 5.4037-.521 1.7088-.691 3.169-1.8606 4.1959-3.3608 1.027-1.5003 1.5746-3.2639 1.5736-5.0677-.0013-2.4177-.9872-4.7359-2.7409-6.4449-1.7537-1.7091-4.1316-2.6692-6.611-2.6692Zm0 13.2537c-1.6509 0-2.9891-1.4299-2.9891-4.1396 0-2.7097 1.3382-4.1296 2.9891-4.1296 1.651 0 2.9943 1.4249 2.9943 4.1296s-1.3433 4.1196-2.9943 4.1196v.02Z" />
          <path d="M88.3709 32.147v4.7145h-2.6046v12.6687h-6.1372V36.8615h-2.6097V32.147h2.6097v-3.9746h6.1372v3.9746h2.6046Z" />
          <path d="M94.8537 44.1606c.1437.4637.4459.8655.8564 1.1384a2.2163 2.2163 0 0 0 1.3996.3615c1.0254 0 1.8201-.385 1.8201-1.1949 0-.655-.5127-.8899-1.3843-1.0799l-3.4865-.7c-2.9686-.6149-4.9118-1.9998-4.9118-5.0594 0-3.7047 3.4096-5.8695 7.6087-5.8695 4.8711 0 7.5271 2.1998 8.0801 5.6395h-5.7524c-.1948-.73-.7486-1.3999-2.179-1.3999-.9075 0-1.6612.385-1.6612 1.1599 0 .5399.3948.8099 1.2254.9649l3.512.8249c3.2502.7349 4.9942 2.3948 4.9942 5.2145 0 3.7096-3.451 5.7544-7.8038 5.7544-4.558 0-7.8445-1.9998-8.3983-5.7544h6.0808Z" />
        </motion.g>
      </g>
    </StyledLargeLogo>
  );
};

export default LargeLogo;
