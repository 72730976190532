import {motion} from 'framer-motion';
import styled from 'styled-components';

import {COLOR_ORANGE} from '@/theme/colors';
import {pxToRem} from '@/theme/grid';
import {QUERY_GREATER_THAN_MOBILE, USING_MOUSE} from '@/theme/mediaQueries';
import {PREVENT_CLICKS} from '@/theme/pointerEvents';
import {
  TRANSITION_EASE_ON,
  TRANSITION_SPEED_REGULAR,
} from '@/theme/transitions';
import {Z_INDEX_CURSOR} from '@/theme/zIndex';

const CURSOR_SIZE = pxToRem(80, false);
const CURSOR_POSITION = pxToRem(45, false);

export const CursorWrapper = styled.div`
  display: none;

  @media ${QUERY_GREATER_THAN_MOBILE} {
    @media ${USING_MOUSE} {
      position: fixed;
      top: 0;
      left: 0;
      transform-origin: 50% 50%;
      z-index: ${Z_INDEX_CURSOR};
      ${PREVENT_CLICKS}
      display: flex;
      transition: transform ${TRANSITION_SPEED_REGULAR}s ${TRANSITION_EASE_ON};
      will-change: transform;
    }
  }
`;

export const StyledCursor = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${COLOR_ORANGE};
  border-radius: 50%;
  height: ${CURSOR_SIZE};
  width: ${CURSOR_SIZE};
  margin: -${CURSOR_POSITION} 0 0 -${CURSOR_POSITION};
  transform-origin: 50% 50%;
  z-index: ${Z_INDEX_CURSOR};
  ${PREVENT_CLICKS}
`;

export const IconWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
`;
