import Linkedin from '@/components/icons/Linkedin';
import YouTube from '@/components/icons/YouTube';
import Social from '@/components/Social';
import {eventClickSocial} from '@/config/tracking';
import {Platform, Social as ContentfulSocial, theme} from '@/content/cms/types';

import {SocialsWrapper} from './styles';

interface Props {
  className?: string;
  socials: ContentfulSocial[];
  theme?: theme;
  small?: boolean;
}

const Socials = ({className, socials, theme, small, ...rest}: Props) => {
  const icon = (platform: Platform) => {
    switch (platform) {
      case 'YouTube':
        return <YouTube />;

      case 'Linkedin':
        return <Linkedin />;
    }
  };

  return (
    <SocialsWrapper className={className}>
      {socials.map((social, index) => (
        <Social
          key={index}
          url={social.url}
          tracking={eventClickSocial(social.platform)}
          theme={theme}
          label={social.platform}
          small={small}
          {...rest}
        >
          {icon(social.platform)}
        </Social>
      ))}
    </SocialsWrapper>
  );
};

export default Socials;
