import {css, keyframes} from 'styled-components';

import {COLOR_ORANGE} from './colors';
import {pxToRem} from './grid';
import {
  QUERY_GREATER_THAN_MOBILE,
  QUERY_GREATER_THAN_TABLET,
} from './mediaQueries';
import {TRANSITION_EASE_ON, TRANSITION_SPEED_REGULAR} from './transitions';

export interface Underline {
  desktopHeight: string;
  tabletHeight: string;
  mobileHeight: string;
}

export const UNDERLINE_H1 = {
  desktopHeight: pxToRem(15, false),
  tabletHeight: pxToRem(10, false, 'tablet'),
  mobileHeight: pxToRem(5, false, 'mobile'),
};
export const UNDERLINE_H2 = {
  desktopHeight: pxToRem(9, false),
  tabletHeight: pxToRem(7, false, 'tablet'),
  mobileHeight: pxToRem(4, false, 'mobile'),
};
export const UNDERLINE_H2_AND_A_HALF = {
  desktopHeight: pxToRem(8, false),
  tabletHeight: pxToRem(6, false, 'tablet'),
  mobileHeight: pxToRem(3, false, 'mobile'),
};
export const UNDERLINE_H3 = {
  desktopHeight: pxToRem(6, false),
  tabletHeight: pxToRem(4, false, 'tablet'),
  mobileHeight: pxToRem(3, false, 'mobile'),
};
export const UNDERLINE_H4 = {
  desktopHeight: pxToRem(3, false),
  tabletHeight: pxToRem(3, false, 'tablet'),
  mobileHeight: pxToRem(3, false, 'mobile'),
};
export const UNDERLINE_H5 = {
  desktopHeight: pxToRem(3, false),
  tabletHeight: pxToRem(3, false, 'tablet'),
  mobileHeight: pxToRem(3, false, 'mobile'),
};
export const UNDERLINE_H6 = {
  desktopHeight: pxToRem(2, false),
  tabletHeight: pxToRem(2, false, 'tablet'),
  mobileHeight: pxToRem(2, false, 'mobile'),
};

const animateUnderlineIn = (height: string) => keyframes`
  to {
    background-size: 100% ${height}, 0 ${height};
  }
`;

const underlineSize = (height: string, showAfter?: number) =>
  showAfter
    ? css`
        background-size: 0 ${height}, 100% ${height};
        animation: ${animateUnderlineIn(height)} ${TRANSITION_SPEED_REGULAR}s
          ${showAfter}s ${TRANSITION_EASE_ON} forwards;
      `
    : css`
        background-size: 100% ${height}, 0 ${height};
      `;

/**
 * Used to add a custom inset underline.
 *
 * @param multiline Set to `true` to draw across multiple lines, or `false`
 * if text never wraps.
 * @param showAfter Used to animate in underline after given delay in seconds.
 */
export const insetUnderline = (
  underline: Underline,
  multiline?: boolean,
  showAfter?: number,
) => css`
  ${multiline &&
  css`
    display: inline;
  `}

  background: linear-gradient(${COLOR_ORANGE}, ${COLOR_ORANGE});
  background-repeat: no-repeat;
  background-position: 0 ${multiline ? '72%' : '90%'}, 100% 100%;
  ${underlineSize(underline.mobileHeight, showAfter)};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    ${underlineSize(underline.tabletHeight, showAfter)};
  }

  @media ${QUERY_GREATER_THAN_TABLET} {
    ${underlineSize(underline.desktopHeight, showAfter)};
  }
`;
