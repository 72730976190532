import React, {useRef} from 'react';

import Head from 'next/head';

import {Media} from '@/content/cms/types';
import {useGlobals} from '@/contexts/globals';
import useLazyLoadingMedia from '@/hooks/useLazyLoadingMedia';
import getAspectRatioVector2FromString from '@/utils/aspectRatio';
import combineIdWithFileName from '@/utils/combineIdWithFileName';
import Vector2 from '@/utils/math/Vector2';

import {MediaWrapper, objectFit, StyledPicture, StyledVideo} from './styles';

const FILE_TYPES_TO_IGNORE = ['image/gif'];
const VIDEO_TYPES = ['video/mp4', 'video/webm'];

const LOCOMOTIVE_WRAPPER_PARALLAX = {
  'data-scroll': true,
  'data-scroll-speed': 2,
};

const LOCOMOTIVE_INNER_PARALLAX = {
  'data-scroll': true,
  'data-scroll-speed': -1,
};

export const MEDIA_WRAPPER_CLASS = 'media-wrapper';

interface PreloadMediaProps {
  srcset: string;
  isSourceElement?: boolean;
}

const PreloadMedia = ({srcset, isSourceElement}: PreloadMediaProps) => {
  const commonProps = {rel: 'preload', as: 'image'};

  if (isSourceElement) {
    const sources = srcset.split(',');

    return (
      <Head>
        {sources.map((source, index) => {
          const src = source.split(' ');
          const width = src[1].replace('w', '');

          return (
            <link
              key={index}
              {...commonProps}
              href={src[0]}
              media={`(${index === 0 ? 'max' : 'min'}-width: ${width}px)`}
            />
          );
        })}
      </Head>
    );
  }

  const props = {
    ...commonProps,
    imageSrcset: srcset,
  };

  return (
    <Head>
      <link {...props} />
    </Head>
  );
};

interface Props {
  className?: string;
  media: Media;
  /** Example: 16:9. */
  aspectRatio?: string;
  ignoreAspectRatio?: boolean;
  objectFit?: objectFit;
  enableWrapperLocomotive?: boolean;
  enableInnerLocomotive?: boolean;
  loop?: boolean;
  /** **Note**: If used with preload, this will default to `false`. */
  lazy?: boolean;
  preload?: boolean;
  hidden?: boolean;
}

const BaseMedia = ({
  className,
  media,
  aspectRatio,
  ignoreAspectRatio,
  objectFit,
  enableWrapperLocomotive,
  enableInnerLocomotive,
  loop = true,
  lazy: suppliedLazy = true,
  preload = false,
  hidden = false,
}: Props) => {
  // If preload is true, ignore lazy load
  const lazy = preload === true ? false : suppliedLazy;

  const mediaRef = useRef<HTMLPictureElement | HTMLDivElement | null>();
  useLazyLoadingMedia(mediaRef, lazy);

  const {preview} = useGlobals();

  // Prevent gif's breaking build, by ignoring rest
  if (FILE_TYPES_TO_IGNORE.includes(media.contentType)) return null;

  const fileNameBasedOnId = combineIdWithFileName(media.sys.id, media.fileName);

  const locomotiveWrapperScroll =
    enableWrapperLocomotive && LOCOMOTIVE_WRAPPER_PARALLAX;
  const locomotiveInnerScroll =
    enableInnerLocomotive && LOCOMOTIVE_INNER_PARALLAX;

  const aspectRatioVector2 =
    aspectRatio && !ignoreAspectRatio
      ? getAspectRatioVector2FromString(aspectRatio)
      : undefined;

  const wrapperProps = {
    className: `${className} ${MEDIA_WRAPPER_CLASS}`,
    $aspectRatio: aspectRatioVector2,
    $objectFit: objectFit,
    $hasInnerLocomotive: enableInnerLocomotive,
    ref: (element: HTMLElement | null) => (mediaRef.current = element),
    ...locomotiveWrapperScroll,
  };

  // MP4s / WEBMs
  if (VIDEO_TYPES.includes(media.contentType)) {
    let videoMedia = media.url;
    if (!preview) {
      videoMedia = require(`../../../.contentful/${fileNameBasedOnId}?resize&format=webp`);
    }

    const sourceProps = lazy ? {['data-src']: videoMedia} : {src: videoMedia};

    return (
      <>
        {!hidden && (
          <MediaWrapper {...wrapperProps}>
            <StyledVideo
              autoPlay
              loop={loop}
              muted
              playsInline
              aria-label={media.title}
              $lazy={lazy}
              preload={preload ? 'auto' : 'metadata'}
              disableRemotePlayback
              disablePictureInPicture
            >
              <source
                {...sourceProps}
                type={media.contentType}
                {...locomotiveInnerScroll}
              />
            </StyledVideo>
          </MediaWrapper>
        )}
      </>
    );
  }

  // Else, JPEGs / PNGs
  else {
    const asset = {
      src: media.url,
      width: media.width || undefined,
      height: media.height || undefined,
      srcSet: media.url,
    };
    let responsiveMediaWebP = {...asset};
    let responsiveMedia = {...asset};

    if (!preview) {
      responsiveMediaWebP = require(`../../../.contentful/${fileNameBasedOnId}?resize&format=webp`);
      responsiveMedia = require(`../../../.contentful/${fileNameBasedOnId}?resize`);
    }

    // If no aspect ratio given, set default
    if (!wrapperProps.$aspectRatio && !ignoreAspectRatio) {
      wrapperProps.$aspectRatio = new Vector2(
        responsiveMedia.width,
        responsiveMedia.height,
      );
    }

    const sourceProps = lazy
      ? {
          ['data-type']: 'image/webp',
          ['data-srcset']: responsiveMediaWebP.srcSet,
        }
      : {srcSet: responsiveMediaWebP.srcSet, type: 'image/webp'};
    const imgProps = lazy
      ? {
          ['data-src']: responsiveMedia.src,
          ['data-srcset']: responsiveMedia.srcSet,
        }
      : {src: responsiveMedia.src, srcSet: responsiveMedia.srcSet};

    return (
      <>
        {preload && (
          <>
            <PreloadMedia srcset={responsiveMedia.srcSet} />
            <PreloadMedia srcset={responsiveMediaWebP.srcSet} isSourceElement />
          </>
        )}
        {!hidden && (
          <MediaWrapper {...wrapperProps}>
            <StyledPicture $lazy={lazy}>
              <source {...sourceProps} {...locomotiveInnerScroll} />
              <img {...imgProps} alt={media.title} {...locomotiveInnerScroll} />
            </StyledPicture>
          </MediaWrapper>
        )}
      </>
    );
  }
};

export default BaseMedia;
