import {motion} from 'framer-motion';
import styled, {css} from 'styled-components';

import Copy from '@/components/Copy';
import Angle from '@/components/icons/Angle';
import {theme} from '@/content/cms/types';
import {onFocusOutline} from '@/theme/borders';
import {
  COLOR_ALMOST_BLACK,
  COLOR_GREY,
  COLOR_OFF_BLACK,
  COLOR_ORANGE,
  COLOR_WHITE,
  COLOR_WHITE_10,
  COLOR_WHITE_70,
  COLOR_OFF_LATTE,
} from '@/theme/colors';
import {gridGutters, pxToRem} from '@/theme/grid';
import {QUERY_GREATER_THAN_TABLET} from '@/theme/mediaQueries';
import {ALLOW_CLICKS} from '@/theme/pointerEvents';
import {SPACING_S} from '@/theme/spacings';
import {
  TRANSITION_EASE_CUBIC,
  TRANSITION_EASE_ON,
  TRANSITION_SPEED_FAST,
  TRANSITION_SPEED_RAPID,
  TRANSITION_SPEED_REGULAR,
  TRANSITION_SPEED_SLOW,
} from '@/theme/transitions';
import {
  TYPE_FONT_SANS,
  TYPE_SIZE_SANS_SMALL,
  TYPE_WEIGHT_BOLD,
  TYPE_WEIGHT_SEMI_BOLD,
} from '@/theme/type';
import {
  Z_INDEX_CONTENT,
  Z_INDEX_CONTENT_OVERLAY,
  Z_INDEX_SUB_NAV,
} from '@/theme/zIndex';

const SHARED_BUTTON_STYLES = css`
  ${TYPE_FONT_SANS}
  ${TYPE_WEIGHT_SEMI_BOLD}
  white-space: nowrap;
  width: fit-content;

  /* Reset default button styles */
  border: 0;
  background: transparent;
  padding: 0;
  margin: 0;
  cursor: pointer;
`;

const SHARED_PSEUDO_ELEMENT_STYLES = css`
  position: absolute;
  content: '';
`;

const PSEUDO_VERTICAL_LINE_STYLES = css`
  ${SHARED_PSEUDO_ELEMENT_STYLES}
  width: 100%;
  left: 0;
  bottom: 0;
  right: 0;
  height: 1px;
`;

interface SubNavTheme {
  $theme: theme;
}

export const JourneySubNavWrapper = styled(motion.nav)<SubNavTheme>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  ${({$theme}) =>
    $theme === 'dark'
      ? css`
          color: ${COLOR_WHITE};
          background-color: ${COLOR_OFF_BLACK};
        `
      : css`
          color: ${COLOR_OFF_BLACK};
          background-color: ${COLOR_OFF_LATTE};
        `}
  z-index: ${Z_INDEX_SUB_NAV};
  height: ${pxToRem(120, false)};
  ${ALLOW_CLICKS}
  transition: color ${TRANSITION_SPEED_FAST}s ${TRANSITION_EASE_CUBIC},
    background ${TRANSITION_SPEED_FAST}s ${TRANSITION_EASE_CUBIC};

  @media ${QUERY_GREATER_THAN_TABLET} {
    height: ${pxToRem(60, false)};

    &:before {
      ${PSEUDO_VERTICAL_LINE_STYLES}
      border-bottom: 2px solid ${COLOR_GREY};
    }

    &:after {
      ${PSEUDO_VERTICAL_LINE_STYLES}
      width: ${pxToRem(150, false)};
      border-bottom: 2px solid ${COLOR_ORANGE};
    }
  }
`;

export const StyledAngle = styled(Angle)`
  > svg {
    transition: transform ${TRANSITION_SPEED_REGULAR}s ${TRANSITION_EASE_ON};
  }
`;

export const ProgressBar = styled.div`
  display: none;
  width: 100%;
  position: relative;

  @media ${QUERY_GREATER_THAN_TABLET} {
    display: grid;
    grid-template-areas: 'back main gap';
    grid-template-columns: ${pxToRem(150, false)} auto ${pxToRem(150, false)};
  }
`;

interface ChapterTextProps {
  $alwaysVisible: boolean;
}

export const ChapterText = styled.div<ChapterTextProps>`
  text-align: center;
  transition: opacity ${TRANSITION_SPEED_REGULAR}s ${TRANSITION_EASE_ON};

  ${({$alwaysVisible}) =>
    !$alwaysVisible &&
    css`
      opacity: 0;
    `}
`;

export const ProgressCircle = styled.div`
  position: relative;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid transparent;
  background: none;
  z-index: ${Z_INDEX_CONTENT_OVERLAY};
  transform: translateY(-1px) scale(1);
  transition: transform ${TRANSITION_SPEED_RAPID}s ${TRANSITION_EASE_ON};
`;

export const ProgressCircleInner = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${COLOR_GREY};
  transition: transform ${TRANSITION_SPEED_REGULAR}s ${TRANSITION_EASE_ON};
`;

interface ProgressProps {
  $progress: number;
}

export const ChapterItems = styled.div<ProgressProps>`
  display: flex;
  justify-content: space-evenly;
  position: relative;
  grid-area: main;

  @media ${QUERY_GREATER_THAN_TABLET} {
    &:after {
      ${SHARED_PSEUDO_ELEMENT_STYLES}
      width: ${({$progress}) => `${$progress}%`};
      height: 2px;
      left: 0;
      right: 0;
      bottom: 0;
      transition: width ${TRANSITION_SPEED_SLOW}s ${TRANSITION_EASE_ON};
      border-bottom: 2px solid ${COLOR_ORANGE};
      z-index: ${Z_INDEX_CONTENT};
    }
  }
`;

interface ActiveProps {
  $active: boolean;
  $hasBeenActive?: boolean;
  $theme?: theme;
}

const SHARED_NAV_ITEM_STYLES = css<ActiveProps>`
  &:hover,
  &:focus {
    ${ChapterText} {
      opacity: 1;
    }

    ${ProgressCircle} {
      border-color: ${({$active, $hasBeenActive}) =>
        $active || $hasBeenActive ? COLOR_ORANGE : `${COLOR_GREY}`};
    }

    ${ProgressCircleInner} {
      transform: scale(0);
      background-color: ${({$active, $hasBeenActive}) =>
        $active || $hasBeenActive ? COLOR_ORANGE : `${COLOR_GREY}`};
    }
  }

  ${({$active}) =>
    $active &&
    css`
      ${ProgressCircle} {
        border-color: ${COLOR_ORANGE};
      }

      ${ProgressCircleInner} {
        background-color: ${COLOR_ORANGE};
      }
    `}

  ${({$hasBeenActive}) =>
    $hasBeenActive &&
    css`
      ${ProgressCircle} {
        border-color: transparent;
      }

      ${ProgressCircleInner} {
        background-color: ${COLOR_ORANGE};
      }
    `}
`;

export const ChapterItem = styled.button<ActiveProps>`
  ${SHARED_BUTTON_STYLES}
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  height: 100%;

  ${onFocusOutline(true, true)};

  ${({$active}) =>
    $active &&
    css`
      ${ProgressCircleInner} {
        transform: scale(1);
      }
    `};

  ${SHARED_NAV_ITEM_STYLES};
`;

interface ProgressLineMaskProps {
  $lastIndexMask?: boolean;
  $theme: theme;
}

export const ProgressLineMask = styled.div<ProgressLineMaskProps>`
  position: absolute;
  align-self: center;
  background: ${({$theme}) =>
    $theme === 'dark' ? COLOR_OFF_BLACK : COLOR_OFF_LATTE};
  z-index: ${Z_INDEX_CONTENT_OVERLAY};
  transform: ${({$lastIndexMask}) =>
    $lastIndexMask ? `translate(-50%, 100%)` : `translate(-50%)`};
  width: 20px;
  height: 20px;
  transition: background ${TRANSITION_SPEED_FAST}s ${TRANSITION_EASE_CUBIC};

  @media ${QUERY_GREATER_THAN_TABLET} {
    height: 10px;
    transform: initial;
    bottom: 0;
  }
`;

export const ChapterTitle = styled.label<SubNavTheme>`
  ${TYPE_SIZE_SANS_SMALL}
  ${TYPE_WEIGHT_BOLD}
  cursor: pointer;
  margin: 0;
  display: block;
  transition: color ${TRANSITION_SPEED_FAST}s ${TRANSITION_EASE_CUBIC};

  color: ${({$theme}) =>
    $theme === 'dark' ? COLOR_WHITE : COLOR_ALMOST_BLACK};
`;

export const ChapterYears = styled(Copy)<SubNavTheme>`
  color: ${({$theme}) =>
    $theme === 'dark' ? COLOR_WHITE_70 : COLOR_ALMOST_BLACK};
  transition: color ${TRANSITION_SPEED_FAST}s ${TRANSITION_EASE_CUBIC};
  margin: 0;
`;

export const BackToTopButton = styled.button<SubNavTheme>`
  grid-area: back;

  ${SHARED_BUTTON_STYLES}
  ${TYPE_SIZE_SANS_SMALL};

  margin: auto 0 auto ${gridGutters(0.5)};
  color: ${({$theme}) =>
    $theme === 'dark' ? COLOR_WHITE : COLOR_ALMOST_BLACK};
  position: relative;
  opacity: 0.7;
  transition: opacity ${TRANSITION_SPEED_FAST}s ${TRANSITION_EASE_CUBIC};

  &:hover,
  &:focus {
    opacity: 1;
  }

  ${onFocusOutline()};
`;

export const MobileDropdownWrapper = styled.div`
  display: block;
  position: relative;
  width: 100%;
  height: 100%;

  @media ${QUERY_GREATER_THAN_TABLET} {
    display: none;
  }
`;

export const DropdownItem = styled.div<ActiveProps>`
  width: 100%;
  border: none;
  text-align: left;
  color: ${({$theme}) =>
    $theme === 'dark' ? COLOR_WHITE : COLOR_ALMOST_BLACK};
  ${TYPE_SIZE_SANS_SMALL}
  margin: 0 ${gridGutters(2)};
  padding: ${SPACING_S} 0;
  transition: color ${TRANSITION_SPEED_FAST}s ${TRANSITION_EASE_CUBIC},
    background ${TRANSITION_SPEED_FAST}s ${TRANSITION_EASE_CUBIC};
`;

interface DropdownMenuProps {
  $active?: boolean;
  $progress: number;
  $theme: theme;
}

export const DropdownMenu = styled(motion.div)<DropdownMenuProps>`
  display: flex;
  position: absolute;
  top: ${pxToRem(120, false)};
  left: 0;
  right: 0;
  width: 100%;
  flex-direction: column;
  padding: 0 ${gridGutters(2)} ${SPACING_S};
  transition: 0.75s opacity;
  background-color: ${({$theme}) =>
    $theme === 'dark' ? COLOR_OFF_BLACK : COLOR_OFF_LATTE};
  z-index: ${Z_INDEX_CONTENT_OVERLAY};

  &:after {
    ${SHARED_PSEUDO_ELEMENT_STYLES}
    left: ${gridGutters(2)};
    transform: translateX(-50%);
    top: 0;
    width: 2px;
    height: ${({$progress}) => `${$progress}%`};
    background: ${COLOR_ORANGE};
    transition: height ${TRANSITION_SPEED_SLOW}s ${TRANSITION_EASE_ON};
  }
`;

export const DropdownBar = styled.div<SubNavTheme>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid ${COLOR_WHITE_10};
  padding: 0 ${gridGutters(1)};
  height: ${pxToRem(120, false)};
  z-index: ${Z_INDEX_SUB_NAV};
  position: relative;
  background: ${({$theme}) =>
    $theme === 'dark' ? COLOR_OFF_BLACK : COLOR_OFF_LATTE};
`;

export const DropdownTitle = styled.div`
  ${TYPE_SIZE_SANS_SMALL}
`;

export const DropdownEra = styled.div`
  opacity: 0.8;
  ${TYPE_SIZE_SANS_SMALL}
`;

export const DropdownItemWrapper = styled.button<ActiveProps>`
  ${SHARED_BUTTON_STYLES}

  position: relative;
  display: flex;
  flex: 1;

  &:before {
    ${SHARED_PSEUDO_ELEMENT_STYLES}
    width: 2px;
    height: 100%;
    background: ${COLOR_GREY};
    transform: translateX(-50%);
  }

  &:last-of-type {
    &:before {
      height: 50%;
      transform: translate(-50%, -50%);
    }
  }

  ${onFocusOutline(true, true)}

  ${SHARED_NAV_ITEM_STYLES}
`;

export const CurrentItem = styled.div``;

export const Toggle = styled.button`
  ${SHARED_BUTTON_STYLES}

  display: flex;
  height: 12px;
  width: 12px;

  > svg {
    height: 100%;
  }

  ${onFocusOutline()}
`;
