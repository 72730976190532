import Script from 'next/script';

import {Platform} from '@/content/cms/types';
import {IS_BROWSER} from '@/utils/environment';

// Note: If adding any new tracking events, also add respective events to:
// https://docs.google.com/spreadsheets/d/15GDKjhSq5L8Ihkd6Forq5yD-PMMAsNb53H8Ocd-2oiA/edit#gid=1737849880

const CURRENT_PATH = IS_BROWSER && window.location.pathname;

// TRACKING SCRIPT (Can't use @phntms/react-gtm as has to be Google approved.)

interface Props {
  id: string;
}

export const TrackingEmbed = ({id}: Props) => (
  <>
    <Script
      strategy="lazyOnload"
      data-gtm-id={id}
      src="https://www.gstatic.com/brandstudio/kato/google_tag_manager_component/google_tag_manager_component.js"
    />
    <noscript>
      <iframe
        className="gtm-iframe"
        src={`https://www.googletagmanager.com/ns.html?id=${id}`}
        height="0"
        width="0"
      ></iframe>
    </noscript>
  </>
);

// TRACKING CATEGORIES

export enum EventCategory {
  Sitewide = 'Sitewide',
  Header = 'Header',
  Footer = 'Footer',
  Home = 'Home',
  Journey = 'Journey',
  Thinking = 'Thinking',
  Jobs = 'Jobs',
  Connect = 'Connect',
  FourZeroFour = '404',
}

// SITEWIDE

export const eventClickRichTextLink = (linkTitle?: string) => ({
  action: `Click - Rich Text Link: ${linkTitle}`,
  category: EventCategory.Sitewide,
  label: CURRENT_PATH,
});

export const eventClickSocial = (platform: Platform) => ({
  action: `Click - Social: ${platform}`,
  category: EventCategory.Sitewide,
  label: CURRENT_PATH,
});

export const eventScroll = (
  percentage: number,
  progress: number,
  length: number,
) => ({
  action: 'Scroll Depth',
  category: EventCategory.Sitewide,
  label: `Page: ${CURRENT_PATH}, Percentage: ${percentage}, Pixel progress: ${progress}, Page length: ${length}.`,
});

export const EVENT_PREVIEW = {
  action: 'Close preview',
  category: EventCategory.Sitewide,
  label: CURRENT_PATH,
};

// HEADER

export const EVENT_CLICK_LOGO = {
  action: 'Click - Logo',
  category: EventCategory.Header,
  label: CURRENT_PATH,
};

export const eventToggleHamburger = (toggled: 'Opened' | 'Closed') => ({
  action: `Toggle - Menu: ${toggled}`,
  category: EventCategory.Header,
  label: CURRENT_PATH,
});

export const eventClickHamburgerLink = (linkTitle: string) => ({
  action: `Click - Menu Link: ${linkTitle}`,
  category: EventCategory.Header,
  label: CURRENT_PATH,
});

export const eventClickHamburgerSubLink = (linkTitle: string) => ({
  action: `Click - Sub Menu Link: ${linkTitle}`,
  category: EventCategory.Header,
  label: CURRENT_PATH,
});

// FOOTER

export const eventClickFooterCtaLink = (linkTitle: string) => ({
  action: `Click - CTA Link: ${linkTitle}`,
  category: EventCategory.Footer,
  label: CURRENT_PATH,
});

export const eventClickFooterNavLink = (linkTitle: string) => ({
  action: `Click - Nav Link: ${linkTitle}`,
  category: EventCategory.Footer,
  label: CURRENT_PATH,
});

// HOMEPAGE

export const eventPlayChapterVideo = (chapter?: number) => ({
  action: `Click - Play Chapter Video: ${chapter}`,
  category: EventCategory.Home,
});

export const eventClickDataSource = (title: string) => ({
  action: `Click - Source Link: ${title}`,
  category: EventCategory.Home,
});

// JOURNEY

export const eventSubNavClickLink = (link: string) => ({
  action: `Click - Sub Menu Link: ${link}`,
  category: EventCategory.Journey,
});

export const eventSubNavToggle = (toggled: 'Opened' | 'Closed') => ({
  action: `Toggle - Sub Menu: ${toggled}`,
  category: EventCategory.Journey,
});

export const eventBackToTop = (breakpoint: 'Mobile' | 'Desktop') => ({
  action: `Click - Back To Top`,
  category: EventCategory.Journey,
  label: breakpoint,
});

// THINKING

export const eventClickArticle = (articleSlug: string) => ({
  action: `Click - Article: ${articleSlug}`,
  category: EventCategory.Thinking,
});

export const eventClickFeaturedArticle = (articleSlug: string) => ({
  action: `Click - Featured Article: ${articleSlug}`,
  category: EventCategory.Thinking,
});

export const eventClickFeaturedArticleTopic = (
  articleSlug: string,
  topicTitle: string,
) => ({
  action: `Click - Featured Article Topic - Article: ${articleSlug}, Topic: ${topicTitle}`,
  category: EventCategory.Thinking,
});

export const EVENT_CLICK_THINKING_VIEW_ALL = {
  action: 'Click - View All',
  category: EventCategory.Thinking,
};

export const eventClickArchiveBack = (currentTopic: string) => ({
  action: 'Click - Archive Back to Thinking',
  category: EventCategory.Thinking,
  label: currentTopic,
});

export const eventClickArticleBack = (articleTitle: string) => ({
  action: 'Click - Article Back to Thinking',
  category: EventCategory.Thinking,
  label: articleTitle,
});

export const eventClickArchiveShowPage = (
  currentPage: number,
  newPage: number,
  currentTopic: string,
) => ({
  action: `Click - Archive Show Page - Current Page: ${currentPage}, New Page: ${newPage}`,
  category: EventCategory.Thinking,
  label: currentTopic,
});

export const eventClickShareToSocial = (platform: string) => ({
  action: `Click - Share To ${platform}`,
  category: EventCategory.Thinking,
});

export const eventClickSourceLink = (linkTitle?: string) => ({
  action: `Click - Source Link: ${linkTitle}`,
  category: EventCategory.Thinking,
});

export const eventPlayYouTubeVideo = (videoId?: string) => ({
  action: `Click - Play YouTube Video: ${videoId}`,
  category: EventCategory.Thinking,
});

// JOBS

export const eventClickJob = (jobId: number) => ({
  action: `Click - Job: ${jobId}`,
  category: EventCategory.Jobs,
});

export const eventClickJobBack = (jobTitle: string) => ({
  action: 'Click - Job Back to Jobs',
  category: EventCategory.Thinking,
  label: jobTitle,
});

export const eventSubmitJobForm = (jobId: number) => ({
  action: `Submit - Form: ${jobId}`,
  category: EventCategory.Jobs,
});

export const eventClickJobFormFileInput = (fieldId: string) => ({
  action: `Click - Form File Input: ${fieldId}`,
  category: EventCategory.Jobs,
});

// Connect

export const EVENT_SUBMIT_FORM_BOX = {
  action: 'Submit - FormBox',
  category: EventCategory.Connect,
};

// SHARED

export const eventClickFilter = (
  category: EventCategory,
  filterTitle: string,
) => ({
  action: `Click - Filter: ${filterTitle}`,
  category: category,
});

// 404

export const eventClick404Link = (linkTitle: string) => ({
  action: `Click - Link: ${linkTitle}`,
  category: EventCategory.FourZeroFour,
});
