import {AnimatePresence} from 'framer-motion';

import {useAnimationSettings} from '@/contexts/animationSettings';

interface Props {
  children: React.ReactNode;
  animate?: boolean;
}

const SkipInitialAnimatePresence = ({
  children,
  animate = true,
  ...rest
}: Props) => {
  const {enabled} = useAnimationSettings();

  return (
    <AnimatePresence initial={animate && enabled} {...rest}>
      {children}
    </AnimatePresence>
  );
};

export default SkipInitialAnimatePresence;
