import {ArticleListItem} from '@/content/cms/types';

export const dateIsoToReadable = (isoDate: string) => {
  const dateObject = new Date(isoDate);
  return dateObject.toLocaleString('default', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    timeZone: 'America/Los_Angeles',
  });
};

export const sortPublishDateDescending = (articles: ArticleListItem[]) => {
  return articles.sort(
    (first, second) =>
      new Date(second.publishDate).getTime() -
      new Date(first.publishDate).getTime(),
  );
};
