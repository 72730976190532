import React, {createContext, useEffect, useState} from 'react';

interface Props {
  enabled: boolean;
}

const AnimationSettingsContext = createContext<Props>({} as Props);

const AnimationSettingsProvider: React.FC = ({children}) => {
  const [enabled, setEnabled] = useState(false);
  useEffect(() => {
    window.addEventListener('load', () => {
      setEnabled(true);
    });
  }, []);

  return (
    <AnimationSettingsContext.Provider value={{enabled}}>
      {children}
    </AnimationSettingsContext.Provider>
  );
};

const useAnimationSettings = () => React.useContext(AnimationSettingsContext);

export {AnimationSettingsProvider, useAnimationSettings};
