// TRANSITION SPEEDS

export const TRANSITION_SPEED_RAPID = 0.15;
export const TRANSITION_SPEED_FAST = 0.25;
export const TRANSITION_SPEED_REGULAR = 0.4;
export const TRANSITION_SPEED_SLOW = 0.6;
export const TRANSITION_SPEED_SLOTH = 1;
export const TRANSITION_SPEED_GLACIAL = 1.65;

// EASE CONFIGS

export const EASE_ON = [0.2, 0.85, 0.45, 1];
export const EASE_OUT = [0.7, 0.005, 0.3, 1];
export const EASE_CUBIC = [0.6, 0, 0.2, 1];

// TIMING FUNCTIONS

export const TRANSITION_EASE_ON = `cubic-bezier(${[...EASE_ON]})`;
export const TRANSITION_EASE_OUT = `cubic-bezier(${[...EASE_OUT]})`;
export const TRANSITION_EASE_CUBIC = `cubic-bezier(${[...EASE_CUBIC]})`;

// STAGGER SPEEDS

export const TRANSITION_STAGGER_FAST = 0.0008;
export const TRANSITION_STAGGER_SWIFT = 0.01;
export const TRANSITION_STAGGER_REGULAR = 0.05;

// PAGE TRANSITIONS

export const PAGE_TRANSITION_DELAY = 450;
