import {useEffect, useState} from 'react';

/**
 * Returns media query result on change.
 *
 * **Warning**: Hook only works if in client, `defaultValue` can be used to
 * return a default if SSR.
 */
const useClientMediaQuery = (mediaQuery: string, defaultValue?: boolean) => {
  const [matching, setMatching] = useState<boolean | undefined>(defaultValue);

  useEffect(() => {
    const matchMedia = window.matchMedia(mediaQuery);
    const handleMediaChange = () => setMatching(matchMedia.matches);

    // Run on initial load
    handleMediaChange();

    // Listen for changes
    try {
      matchMedia.addEventListener('change', handleMediaChange);
    } catch (error) {
      try {
        // iOS 13
        matchMedia.addListener(handleMediaChange);
      } catch (iosError) {
        console.error(iosError);
      }
    }

    return () => {
      try {
        matchMedia.removeEventListener('change', handleMediaChange);
      } catch (error) {
        try {
          // iOS 13
          matchMedia.removeListener(handleMediaChange);
        } catch (iosError) {
          console.error(iosError);
        }
      }
    };
  }, [mediaQuery, setMatching]);

  return matching;
};

export default useClientMediaQuery;
