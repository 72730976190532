import {motion} from 'framer-motion';

import {SPACING_XL_VALUE} from '@/theme/spacings';
import {EASE_ON, TRANSITION_SPEED_SLOW} from '@/theme/transitions';

import SkipInitialAnimatePresence from '../SkipInitialAnimatePresence';
import {StyledCopy} from './styles';

export type serifSizes = 'regular' | 'large';
export type sansSizes = 'small' | 'regular';
export type revealState = 'initial' | 'animate';

interface Props {
  className?: string;
  /**
   * Defaults to `regular` if left `undefined`.
   *
   * **Note**: If this and `sansSerif` are declared, `sansSerif` takes priority.
   */
  serifSize?: serifSizes;
  sansSize?: sansSizes;
  animate?: boolean;
  /**
   * Define if you want to control when copy reveals. Else, copy reveals
   * when in view by default.
   */
  revealState?: revealState;
  revealDelay?: number;
}

const Copy: React.FC<Props> = ({
  className,
  serifSize = 'regular',
  sansSize,
  animate = false,
  revealState,
  revealDelay,
  children,
}) => {
  return animate ? (
    <SkipInitialAnimatePresence>
      <StyledCopy
        as={motion.p}
        className={className}
        $serifSize={serifSize}
        $sansSize={sansSize}
        initial="initial"
        animate={
          animate && (revealState === 'animate' || !revealState)
            ? 'animate'
            : undefined
        }
        variants={{
          initial: {opacity: 0, y: SPACING_XL_VALUE},
          animate: {
            opacity: 1,
            y: 0,
            transition: {
              delay: revealDelay,
              duration: TRANSITION_SPEED_SLOW,
              ease: EASE_ON,
            },
          },
        }}
      >
        {children}
      </StyledCopy>
    </SkipInitialAnimatePresence>
  ) : (
    <StyledCopy
      className={className}
      $serifSize={serifSize}
      $sansSize={sansSize}
    >
      {children}
    </StyledCopy>
  );
};

export default Copy;
