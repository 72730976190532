import {pxToRem} from '@/theme/grid';
import {SVGRProps} from '@/utils/svgrProps';

import IconWrapper from '../IconWrapper';
import LinkedinSVG from './icon.svg';

const Linkedin: React.FC<SVGRProps> = ({
  className,
  width = pxToRem(18),
  height = pxToRem(18),
}) => (
  <IconWrapper width={width} height={height} className={className}>
    <LinkedinSVG width="100%" height="100%" />
  </IconWrapper>
);

export default Linkedin;
