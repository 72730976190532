import styled from 'styled-components';

import {pxToRem} from '@/theme/grid';
import {
  TRANSITION_EASE_CUBIC,
  TRANSITION_SPEED_FAST,
} from '@/theme/transitions';

export const StyledSmallLogo = styled.svg`
  height: ${pxToRem(30)};
  transition: fill ${TRANSITION_SPEED_FAST}s ${TRANSITION_EASE_CUBIC};
`;
