import {useEffect, useState} from 'react';

import {useRouter} from 'next/router';

/** Returns routing state on change with minimum delay before finishing. */
const useRouting = (minimumDelay: number) => {
  const router = useRouter();

  const [routing, setRouting] = useState(false);
  const [started, setStarted] = useState<Date>();

  useEffect(() => {
    const handleRouteStart = () => {
      setRouting(true);
      setStarted(new Date());
    };

    const handleRouteComplete = () => {
      if (started) {
        const timeTakenToRoute = new Date().getTime() - started.getTime();

        // If page loaded too quick, ensure at least minimumDelay is waited
        if (timeTakenToRoute < minimumDelay) {
          const timeLeft = minimumDelay - timeTakenToRoute;
          setTimeout(() => setRouting(false), timeLeft);
        }

        // Else, finished routing
        else setRouting(false);
      }

      // If initial date wasn't set, wait full duration
      else setTimeout(() => setRouting(false), minimumDelay);
    };

    // Listen for changes
    router.events.on('routeChangeStart', handleRouteStart);
    router.events.on('routeChangeComplete', handleRouteComplete);
    router.events.on('routeChangeError', handleRouteComplete);

    return () => {
      router.events.off('routeChangeStart', handleRouteStart);
      router.events.off('routeChangeComplete', handleRouteComplete);
      router.events.off('routeChangeError', handleRouteComplete);
    };
  }, [minimumDelay, router.events, started]);

  return routing;
};

export default useRouting;
