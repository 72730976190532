import {useEffect, useRef} from 'react';

import {QUERY_GREATER_THAN_TABLET, USING_MOUSE} from '@/theme/mediaQueries';

import useClientMediaQuery from './useClientMediaQuery';

const useReloadOnContextChange = () => {
  const usingMouseRef = useRef<boolean>();
  const isDesktopRef = useRef<boolean>();

  const isDesktop = useClientMediaQuery(QUERY_GREATER_THAN_TABLET);
  const isUsingMouse = useClientMediaQuery(USING_MOUSE);

  useEffect(() => {
    const wasUsingMouse = usingMouseRef.current;
    const wasDesktop = isDesktopRef.current;

    usingMouseRef.current = isUsingMouse;
    isDesktopRef.current = isDesktop;

    if (wasUsingMouse === undefined || wasDesktop === undefined) return;

    if (
      wasUsingMouse === usingMouseRef.current &&
      wasDesktop === isDesktopRef.current
    )
      return;

    window.location.reload();
  }, [isUsingMouse, isDesktop]);
};

export default useReloadOnContextChange;
