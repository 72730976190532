import {gql} from 'graphql-request';

// SHARED

const MEDIA = `
  sys {
    id
  }
  title
  fileName
  contentType
  width
  height
  url
`;

const MEDIA_URL = `
  title
  url
`;

const LINK = `
  title
  description
  url
  page {
    slug
  }
`;

const BASE_RICH_TEXT = `
  json
`;

const RICH_TEXT_LINKS = `
  entries {
    hyperlink {
      ... on Link {
        sys {
          id
        }
        ${LINK}
      }
    }
    inline {
      ... on Link {
        sys {
          id
        }
        ${LINK}
      }
    }
  }
`;

const RICH_TEXT_MEDIA = `
  assets {
    block {
      ${MEDIA}
    }
  }
`;

const USER_NAME_AND_TITLE = `
  name
  titleRichText {
    ${BASE_RICH_TEXT}
  }
`;

const USER = `
  ${USER_NAME_AND_TITLE}
  summary
  media {
    ${MEDIA}
  }
  headingLevel
`;

const YOUTUBE_VIDEO = `
  id
  title
  caption
  duration
  thumbnail {
    ${MEDIA}
  }
`;

const TOPIC = `
  title
  slug
`;

const MILESTONE = `
  ... on ComponentMilestone {
    milestoneHeading {
      ${BASE_RICH_TEXT}
    }
    milestoneCopy {
      ${BASE_RICH_TEXT}
    }
    relatedMedia {
      ${MEDIA}
    }
    imageInline
    isCarMedia
    year
  }
`;

export const COLLECTION_TOPICS = `
  topicsCollection(limit: 6) {
    items {
      ${TOPIC}
    }
  }
`;

const COLLECTION_AUTHORS = `
  authorsCollection(limit: 6) {
    items {
      name
    }
  }
`;

const COLLECTION_SOCIALS = `
  socialsCollection(limit: 2) {
    items {
      url
      platform
    }
  }
`;

export const ARTICLE_LIST_ITEM = `
  slug
  ${COLLECTION_TOPICS}
  relatedTopic {
    ${TOPIC}
  }
  title
  media {
    ${MEDIA}
  }
  ${COLLECTION_AUTHORS}
  publishDate
  metaDescription
`;

// BLOCKS

const ON_BLOCK_HEADING = `
  ... on BlockHeading {
    styledHeading {
      ${BASE_RICH_TEXT}
    }
    headingLevel
    copyRichText {
      ${BASE_RICH_TEXT}
      links {
        ${RICH_TEXT_LINKS}
      }
    }
    hasGutters
    rightAlign
    copyInline
    showCloserToBlockBelow
    isAtTopOfPage
  }
`;

const ON_BLOCK_MEDIA = `
  ... on BlockMedia {
    media {
      ${MEDIA}
      description
    }
    id
    isAtTopOfPage
  }
`;

const ON_BLOCK_COLUMNS = `
  ... on BlockColumns {
    styledHeading {
      ${BASE_RICH_TEXT}
    }
    headingSource {
      ${LINK}
    }
    headingLevel
    leftColumn {
      ${BASE_RICH_TEXT}
      links {
        ${RICH_TEXT_LINKS}
        ${RICH_TEXT_MEDIA}
      }
    }
    rightColumn {
      ${BASE_RICH_TEXT}
      links {
        ${RICH_TEXT_LINKS}
        ${RICH_TEXT_MEDIA}
      }
    }
    hasGutters
  }
`;

const ON_BLOCK_RICH_TEXT = `
  ... on BlockRichText {
    text {
      ${BASE_RICH_TEXT}
      links {
        ${RICH_TEXT_LINKS}
      }
    }
  }
`;

const ON_BLOCK_QUOTE = `
  ... on BlockQuote {
    quote
    source {
      ${LINK}
    }
  }
`;

const ON_BLOCK_STAT = `
  ... on BlockStat {
    stat
    copy
    source {
      ${LINK}
    }
  }
`;

const ON_BLOCK_YOUTUBE = `
  ... on BlockYoutube {
    ${YOUTUBE_VIDEO}
  }
`;

const ON_BLOCK_CAROUSEL = `
  ... on BlockCarousel {
    heading
    copy
    contentCollection(limit: 10) {
      items {
        ${USER}
      }
    }
    mediaAspectRatio
    centerMedia
    hasGutters
    isFullWidthOnTheJourneyPage
  }
`;

const ON_BLOCK_YOUTUBE_CHAPTERS = `
  ... on BlockYoutubeChapters {
    heading
    videosCollection {
      items {
        ${YOUTUBE_VIDEO}
      }
    }
  }
`;

const ON_BLOCK_TEAM_QUOTE = `
  ... on BlockTeamQuote {
    quote,
    source {
      ${USER_NAME_AND_TITLE}
    }
    image {
      ${MEDIA}
    }
  }
`;

const ON_BLOCK_LINE_CHART = `
  ... on BlockLineChart {
    dataSourceOne {
      title
      description
      url
    }
    dataSourceTwo {
      title
      description
      url
    }
    lineChartTitle {
      ${BASE_RICH_TEXT}
    }
    sourceLabel
    tooltipOneCopy
    tooltipTwoCopy
  }
`;

const PARAGRAPH_WITH_MEDIA = `
  heading
  headingLevel
  eyebrow
  copy {
    ${BASE_RICH_TEXT}
  }
  media {
    ${MEDIA}
  }
`;

const ON_BLOCK_PARAGRAPHS_WITH_MEDIA = `
  ... on BlockParagraphsWithMedia {
    paragraphsCollection(limit: 5) {
      items {
        ${PARAGRAPH_WITH_MEDIA}
      }
    }
    id
    imageAlignLeft
    centerParagraphs
  }
`;

const ON_BLOCK_MEDIA_COLUMNS = `
  ... on BlockMediaColumns {
    heading
    copy
    leftColumn {
      ${PARAGRAPH_WITH_MEDIA}
    }
    rightColumn {
      ${PARAGRAPH_WITH_MEDIA}
    }
  }
`;

const ON_BLOCK_JOURNEY_INTRO = `
  ... on BlockJourneyIntro {
    title
    subtitle {
      ${BASE_RICH_TEXT}
    }
    introCopy {
      ${BASE_RICH_TEXT}
    }
    mainVideo {
      ${MEDIA}
    }
    secondaryVideo {
      ${MEDIA}
    }
  }
`;

const JOURNEY_LINE = `
  from
  to
`;

const JOURNEY_LABEL = `
  title
  subTitle
  color
`;

const ON_BLOCK_JOURNEY_TIMELINE = `
  ... on BlockJourneyTimeline {
    timelineElementsCollection(limit: 25) {
      items {
        chapter
        title {
          ${BASE_RICH_TEXT}
        }
        copy {
          ${BASE_RICH_TEXT}
        }
        year
        line {
          ${JOURNEY_LINE}
        }
        image {
          ${MEDIA}
          description
        }
        inline
        fullWidthTitle
        copyInline
        imageFloatingOverTitle
        era
        navigationTitle
        mediaAlignRight
        tiltMedia
        imageMobileAlternative {
          ${MEDIA}
        }
        label {
          ${JOURNEY_LABEL}
        }
      }
    }
  }
`;

const ON_BLOCK_JOURNEY_MILESTONES = `
  ... on BlockMilestones {
    navigationTitle,
    era
    heading
    backgroundColor
    chapter
    textAlign
    label {
      ${JOURNEY_LABEL}
    }
    subHeadingCopy {
      json
    }
  milestoneListCollection(limit: 15) {
    items {
      __typename
        ${MILESTONE}
        ${ON_BLOCK_CAROUSEL}
        ${ON_BLOCK_COLUMNS}
      }
    }
  }
`;

const ON_BLOCK_STICKY_MEDIA_CONTENT = `
  ... on BlockStickyMediaContent {
    title
    copy
    media {
      ${MEDIA}
    }
    id
  }
`;

const ALL_BLOCKS = `
  ${ON_BLOCK_HEADING}
  ${ON_BLOCK_MEDIA}
  ${ON_BLOCK_COLUMNS}
  ${ON_BLOCK_CAROUSEL}
  ${ON_BLOCK_YOUTUBE_CHAPTERS}
  ${ON_BLOCK_TEAM_QUOTE}
  ${ON_BLOCK_PARAGRAPHS_WITH_MEDIA}
  ${ON_BLOCK_MEDIA_COLUMNS}
  ${ON_BLOCK_JOURNEY_INTRO}
  ${ON_BLOCK_JOURNEY_TIMELINE}
  ${ON_BLOCK_JOURNEY_MILESTONES}
  ${ON_BLOCK_STICKY_MEDIA_CONTENT}
  ${ON_BLOCK_LINE_CHART}
`;

// QUERIES

export const QUERY_GLOBALS = gql`
  query ($preview: Boolean) {
    globalsCollection(preview: $preview, limit: 1) {
      items {
        siteTitle
        titleTemplate
        siteDescription
        baseSiteUrl
        shareAsset {
          ${MEDIA_URL}
        }
        siteTwitterUsername
        header {
          openedLabel
          hoveringLabel
          linksCollection {
            items {
              ${LINK}
            }
          }
          subLinksCollection {
            items {
              ${LINK}
            }
          }
          ${COLLECTION_SOCIALS}
          media {
            ${MEDIA}
          }
          subNavBackToTopLabel
        }
        footer {
          discoverNextHeading
          copyright
          linksCollection {
            items {
              ${LINK}
            }
          }
          ${COLLECTION_SOCIALS}
        }
        articlePageSize
        articleHeaderLabel
        articleFootnotesHeader
        articleTopicsHeader
        relatedArticlesHeader
        youTubeChapterCurrentHeading
        youTubeChapterPrologueHeading
        youTubePlayVideoCopy
        usersPrefix
        fieldRequiredErrorLabel
      }
    }
  }
`;

export const QUERY_PAGE_PAYLOAD_ONE = gql`
  query ($preview: Boolean, $slug: String!) {
    pageCollection(preview: $preview, where: {slug: $slug}, limit: 1) {
      items {
        slug
        title
        headerLabel
        shareAsset {
          ${MEDIA_URL}
        }
        content {
          ... on Thinking {
            filtersLabel
            viewAllLabel
            featuredArticlesHeading
            featuredArticlesCollection(limit: 6) {
              items {
                ${ARTICLE_LIST_ITEM}
              }
            }
          }
          ... on Archive {
            paginationPreviousLabel
            paginationNextLabel
          }
          ... on JoinUs {
            jobsHeading
            filtersLabel
            noJobsFound
          }
          ... on JobForm {
            heading
            subHeading
            textFieldPlaceholder
            emailFieldPlaceholder
            phoneFieldPlaceholder
            uploadFileButtonLabel
            accordionLabel
            submitButtonLabel
            formSubmittedSuccessHeading
            formSubmittedSuccessCopy
            missingFieldsLabel
          }
          ... on FourZeroFour {
            heading
            description
            link {
              ${LINK}
            }
          }
        }
        blocksCollection(limit: 9) {
          items {
            __typename
            ${ALL_BLOCKS}
          }
        }
        footerCta {
          heading
          link {
            ${LINK}
          }
        }
        metaDescription
      }
    }
  }
`;

export const QUERY_PAGE_PAYLOAD_TWO = gql`
  query ($preview: Boolean, $slug: String!) {
    pageCollection(preview: $preview, where: {slug: $slug}, limit: 1) {
      items {
        blocksCollection(skip: 9, limit: 9) {
          items {
            __typename
            ${ALL_BLOCKS}
          }
        }
      }
    }
  }
`;

export const QUERY_ARTICLES = gql`
  query ($preview: Boolean, $relatedTopicSlug: String, $slugsToIgnore: [String], $limit: Int) {
    articleCollection(
      preview: $preview
      order: publishDate_DESC
      where: {relatedTopic: {slug: $relatedTopicSlug}, slug_not_in: $slugsToIgnore}
      limit: $limit
    ) {
      items {
        ${ARTICLE_LIST_ITEM}
      }
    }
  }
`;

export const QUERY_PAGED_ARTICLES = gql`
  query ($preview: Boolean, $skip: Int, $limit: Int) {
    articleCollection(
      preview: $preview
      order: publishDate_DESC
      skip: $skip
      limit: $limit
    ) {
      items {
        ${ARTICLE_LIST_ITEM}
      }
      total
    }
  }
`;

export const QUERY_TOPIC_PAGED_ARTICLES = gql`
  query ($preview: Boolean, $slug: String, $skip: Int, $limit: Int) {
    topicCollection(preview: $preview, where: {slug: $slug}, limit: 1) {
      items {
        linkedFrom {
          articleCollection(
            skip: $skip
            limit: $limit,
          ) {
            items {
              ${ARTICLE_LIST_ITEM}
            }
            total
          }
        }
      }
    }
  }
`;

export const QUERY_ARTICLE = gql`
  query ($preview: Boolean, $slug: String!) {
    articleCollection(preview: $preview, where: {slug: $slug}, limit: 1) {
      items {
        ${ARTICLE_LIST_ITEM}
        blocksCollection {
          items {
            __typename
            ${ON_BLOCK_MEDIA}
            ${ON_BLOCK_RICH_TEXT}
            ${ON_BLOCK_QUOTE}
            ${ON_BLOCK_STAT}
            ${ON_BLOCK_YOUTUBE}
          }
        }
      }
    }
  }
`;

export const QUERY_ARTICLES_COUNT = gql`
  query ($preview: Boolean) {
    articleCollection(preview: $preview) {
      total
    }
  }
`;

export const QUERY_TOPIC_ARTICLES_COUNT = gql`
  query ($preview: Boolean, $slug: String) {
    topicCollection(preview: $preview, where: {slug: $slug}, limit: 1) {
      items {
        linkedFrom {
          articleCollection {
            total
          }
        }
      }
    }
  }
`;

export const QUERY_TOPICS = gql`
  query ($preview: Boolean) {
    topicCollection(preview: $preview, order: title_ASC) {
      items {
        ${TOPIC}
      }
    }
  }
`;
