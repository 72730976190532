import styled from 'styled-components';

import Logo from '@/components/Logo';
import {gridGutters} from '@/theme/grid';
import {QUERY_GREATER_THAN_MOBILE} from '@/theme/mediaQueries';
import {ALLOW_CLICKS, PREVENT_CLICKS} from '@/theme/pointerEvents';
import {SPACING_L, SPACING_M} from '@/theme/spacings';
import {Z_INDEX_HEADER} from '@/theme/zIndex';

export const StyledHeader = styled.header`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: ${SPACING_M} ${gridGutters(1)} 0;
  z-index: ${Z_INDEX_HEADER};
  ${PREVENT_CLICKS}

  @media ${QUERY_GREATER_THAN_MOBILE} {
    padding-top: ${SPACING_L};
  }
`;

export const StyledLogo = styled(Logo)`
  ${ALLOW_CLICKS}
`;
