import {useState} from 'react';

const useToggle = (
  initial = false,
): [
  boolean,
  () => void,
  React.Dispatch<React.SetStateAction<boolean>>,
  () => void,
] => {
  const [toggled, setToggled] = useState(initial);

  return [
    toggled,
    () => setToggled((previous) => !previous),
    setToggled,
    () => setToggled(initial),
  ];
};

export default useToggle;
