import React, {useState} from 'react';

import {DEPARTMENT_ALL} from '@/content/jobs';
import {Department} from '@/content/jobs/types';

interface Props {
  joinUsDepartment: Department;
  setJoinUsDepartment: (department: Department) => void;
}

const UserBehaviorContext = React.createContext<Props>({} as Props);

const UserBehaviorProvider: React.FC = ({children}) => {
  const [joinUsDepartment, setJoinUsDepartment] = useState(DEPARTMENT_ALL);

  return (
    <UserBehaviorContext.Provider
      value={{
        joinUsDepartment,
        setJoinUsDepartment,
      }}
    >
      {children}
    </UserBehaviorContext.Provider>
  );
};

const useUserBehavior = () => React.useContext(UserBehaviorContext);

export {UserBehaviorProvider, useUserBehavior};
