import {motion} from 'framer-motion';
import styled from 'styled-components';

import Button from '@/components/Button';
import {theme} from '@/content/cms/types';
import {COLOR_ALMOST_BLACK, COLOR_WHITE} from '@/theme/colors';
import {ALLOW_CLICKS, PREVENT_CLICKS} from '@/theme/pointerEvents';
import {SPACING_M} from '@/theme/spacings';
import {
  TRANSITION_EASE_CUBIC,
  TRANSITION_SPEED_REGULAR,
} from '@/theme/transitions';
import {Z_INDEX_SUB_NAV} from '@/theme/zIndex';

export const JourneyBackToTopWrapper = styled(motion.div)`
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  z-index: ${Z_INDEX_SUB_NAV};
  padding-bottom: ${SPACING_M};
  ${PREVENT_CLICKS}
`;

interface Props {
  $theme: theme;
}

export const StyledButton = styled(Button)<Props>`
  color: ${({$theme}) =>
    $theme === 'dark' ? COLOR_ALMOST_BLACK : COLOR_WHITE};
  background: ${({$theme}) =>
    $theme === 'dark' ? COLOR_WHITE : COLOR_ALMOST_BLACK};
  transition: color ${TRANSITION_SPEED_REGULAR}s ${TRANSITION_EASE_CUBIC},
    background ${TRANSITION_SPEED_REGULAR}s ${TRANSITION_EASE_CUBIC};
  ${ALLOW_CLICKS}
`;
