import {Job} from './types';

const GREENHOUSE_BOARD_TOKEN = process.env.NEXT_PUBLIC_GREENHOUSE_BOARD_TOKEN;
export const BASE_URL = `https://boards-api.greenhouse.io/v1/boards/${GREENHOUSE_BOARD_TOKEN}/jobs/`;
const SUBMISSION_URL = `https://x-greenhouse-dot-x-prod.appspot.com/_grow/api/submit-application`;

const fetchData = async <T>(url: string): Promise<T> => {
  const response = await fetch(url);
  return await response.json();
};

export interface PayLoad {
  status: number;
  jobs: Job[];
}

export const fetchJobs = async () => {
  const response = await fetchData<PayLoad>(`${BASE_URL}?content=true`);
  return response.status === 404 ? [] : response.jobs;
};

export const fetchJob = async (jobId: number) => {
  return fetchData<Job>(`${BASE_URL}/${jobId}?questions=true`);
};

export const submitJobApplication = (formData: FormData) => {
  return fetch(SUBMISSION_URL, {method: 'POST', body: formData});
};
