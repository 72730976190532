import styled from 'styled-components';

export const ICON_WRAPPER_CLASS = 'icon-wrapper';

interface Props {
  $width?: string | number;
  $height?: string | number;
}

export const StyledIconWrapper = styled.div<Props>`
  width: ${({$width}) => $width};
  height: ${({$height}) => $height};
  display: flex;
  place-items: center;
  place-content: center;
`;
