import styled from 'styled-components';

import {theme} from '@/content/cms/types';
import {COLOR_BLACK, COLOR_WHITE} from '@/theme/colors';
import {Direction} from '@/utils/svgrProps';

import AngleSVG from './icon.svg';

interface Props {
  $direction: Direction;
  $theme: theme;
}

export const StyledAngle = styled(AngleSVG)<Props>`
  transform: rotate(${({$direction}) => $direction});

  > path {
    fill: ${({$theme}) => ($theme === 'dark' ? COLOR_WHITE : COLOR_BLACK)};
  }
`;
