import {AnimatePresence} from 'framer-motion';

import {getSlugPath, SLUG_HOME} from '@/config/routes';
import {EVENT_CLICK_LOGO} from '@/config/tracking';
import {theme} from '@/content/cms/types';

import LargeLogo from './LargeLogo';
import SmallLogo from './SmallLogo';
import {StyledLink} from './styles';

interface Props {
  className?: string;
  theme?: theme;
  smallLogo?: boolean;
  hidden?: boolean;
  animate?: boolean;
}

const Logo = ({
  className,
  theme = 'dark',
  smallLogo,
  hidden,
  animate = true,
}: Props) => (
  <StyledLink
    className={className}
    href={getSlugPath(SLUG_HOME)}
    tracking={EVENT_CLICK_LOGO}
    aria-label="Everyday Robots logo"
    allowRoutingToSamePage
  >
    <AnimatePresence exitBeforeEnter initial={animate}>
      {!hidden &&
        (smallLogo ? (
          <SmallLogo key="small-logo" theme={theme} />
        ) : (
          <LargeLogo key="large-logo" theme={theme} />
        ))}
    </AnimatePresence>
  </StyledLink>
);

export default Logo;
